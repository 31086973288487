import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiCurrencyService } from '../../../services/multi-currency/multi-currency.service';
import { RedemptionCartDataService } from '../../../services/redemption-cart/redemption-cart-data.service';
import { RewardProductDto, UserContext } from '@neo-reward-engine-web/ecom-api';
import { ProgramSettingsService } from '../../../services/program-settings/program-settings.service';
import { Observable } from 'rxjs';
import { UserStatsService } from '../../../services/user-stats/user-stats.service';

/**
 * product detail page modal component with add to cart functionality and image slider
 */
@Component({
  selector: 'neo-reward-engine-web-product-detail-page',
  templateUrl: './product-detail-page.component.html',
  styleUrls: ['./product-detail-page.component.scss'],
})
export class ProductDetailPageComponent implements OnInit {
  @Input()
  public productItem!: RewardProductDto;

  public showAnimation = false;
  public animationTimeout: ReturnType<typeof setTimeout> | undefined;
  public quantity = 1;
  public imgSliderIndex = 0;
  public imgIndividualIndex = 0;
  public numberOfImagesToShow = 3 - 1;

  /**
   *
   * @param cartDataService injected for add to cart functionality
   * @param activeModal injected to be able to close itself when modal
   * @param router injected to be able to navigate to other pages
   * @param multiCurrencyService injected to get localized currency for currency pipe
   * @param programSettingsService injected to retrieve basic program settings e.g. copayment
   * @param userStatsService injected to retrieve user context
   */
  constructor(
    public readonly cartDataService: RedemptionCartDataService,
    public readonly activeModal: NgbActiveModal,
    private readonly router: Router,
    private readonly multiCurrencyService: MultiCurrencyService,
    private readonly programSettingsService: ProgramSettingsService,
    private readonly userStatsService: UserStatsService
  ) {
    //
  }

  ngOnInit(): void {
    this.multiCurrencyService.retrieveMutliCurrencyParams();
  }

  get locale() {
    return this.multiCurrencyService.locale;
  }
  get currency() {
    return this.multiCurrencyService.currencyCode;
  }
  get currFormat() {
    return this.multiCurrencyService.currencyFormat;
  }
  get hasCoPayment() {
    return this.programSettingsService.programSettings$.value?.copayment;
  }
  get userContext$(): Observable<UserContext | null> {
    return this.userStatsService.userContext$;
  }

  nonCashBenefitOfProductItem(productItem: RewardProductDto): number {
    return productItem.nonCashBenefit
      .find(x => x.currency === this.multiCurrencyService.currencyCode)?.value || 0;
  }

  localeNameOfProductItem(
    productItem: RewardProductDto,
    locale: string
  ): string {
    return productItem.name.find((x) => x.context === locale)?.value || '';
  }
  localeDescriptionOfProductItem(
    productItem: RewardProductDto,
    locale: string
  ): string {
    return productItem.description?.find((x) => x.context === locale)?.value || '';
  }

  productsNext(arrayLength: number) {
    if (arrayLength - 2 >= this.imgSliderIndex + this.numberOfImagesToShow) {
      this.imgSliderIndex++;
    }

  }

  productsPrevious() {
    if (this.imgSliderIndex > 0) {
      this.imgSliderIndex--;
    }
  }
  /** to navigate to image by clicking on the image instead of slider navigation */
  selectImg(i: number) {
    this.imgIndividualIndex = i;
  }
  /** for image slider */
  swipeRight() {
    if (
      this.productItem.productImages &&
      this.imgIndividualIndex < this.productItem.productImages.length - 1
    ) {
      this.imgIndividualIndex++;
    } else {
      this.imgIndividualIndex = 0;
    }
  }
  /** for image slider */
  swipeLeft() {
    if (this.imgIndividualIndex > 0) {
      this.imgIndividualIndex--;
    } else if (this.productItem.productImages) {
      this.imgIndividualIndex = this.productItem.productImages.length - 1;
    }
  }

  close() {
    this.activeModal.close();
  }

  clickOnCart() {
    this.activeModal.close();
    this.router.navigate(['/cart'], { queryParamsHandling: 'preserve' });
  }
  /** adds to cartdataservice cart */
  addToCart(productItem: RewardProductDto) {
    this.showAnimation = true;
    this.animationTimeout = setTimeout(() => {
      this.showAnimation = false;
      this.cartDataService.rewardProductToCart(productItem, this.quantity);
      this.activeModal.close();
    }, 1750);
  }
}
