<div *transloco="let t;">
<!--    <div style="font: normal normal bold 20px/24px HeidelbergGothicMl, sans-serif; color: white; letter-spacing: 0px;">-->
<!--        {{t('header.miniDashboard.infoColumn.availablePoints')}}-->
<!--    </div>-->
<!--    <div style="font: normal normal bold 72px/86px HeidelbergGothicMl, sans-serif; color: white; letter-spacing: 0px;">-->
<!--        <i class="icon icon-reward align-middle mr-n4" style="color: white; font-size: 86px;"></i>&nbsp;????-->
<!--    </div>-->
    <div class="mt-n3" style="font: italic normal 300 14px/17px HeidelbergGothicMl, sans-serif; color: white; letter-spacing: 0px;">
        {{t('header.unregisteredDashboard.registerToCollect')}}
    </div>
    <button class="btn btn-white mt-3 w-75 w-md-50" (click)="navigateToRegister()" [disabled]="(!programIsActive && hasProgram)">
        {{t(getRegisterButtonStatusTKey())}}
    </button>
</div>
