import {ToastService} from "../toast/toast.service";
import {defaultToastParameter} from "../toast/toast-config.model";
import {MenuService} from "../menu/menu.service";
import {PublicUserState} from "./user-state.type";

export const showNoRewardUserToast = (toastService: ToastService): void => {
  const toastParams = defaultToastParameter;
  toastParams.toastConfig = {
    autoClose: true,
    dismissible: false,
    duration: 10000,
  };
  toastService.showInfoMessage('auth.noRewardUser', toastParams);
};

export const handleAdminUser = (
  redirectUri: string,
  rewardTokenStorageKey: string,
  rewardUserStorageKey: string,
  adminRedirectUrl: string
): void => {
  if(redirectUri.includes("localhost")){
    sessionStorage.removeItem(rewardTokenStorageKey);
    sessionStorage.removeItem(rewardUserStorageKey);
  }
  window.location.href = adminRedirectUrl;
};

export const handleCommunityUserEnabled = (menuService: MenuService): void =>
  menuService.setMenuItems('COMMUNITY');


export const handleCommunityUserUnregistered = (menuService: MenuService): void =>
  menuService.setMenuItems('COMMUNITY-UNREGISTERED');

export const handleCommunityPublic = (menuService: MenuService): void =>
  menuService.setMenuItems('PUBLIC');

