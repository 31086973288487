import {InjectionToken, Provider} from "@angular/core";

export const APP_USER_SERVICE_CONFIG
  = new InjectionToken<string[]>('app.user.service.config');
export const APP_USER_SERVICE_CONFIG_PROVIDER = (config: string[]): Provider => ({
  provide: APP_USER_SERVICE_CONFIG,
  useValue: [...config],
});

/**
 * TFooterConfig
 *
 * SSU specific configuration for the footer
 * properties:
 * aboutUsLink: string - link to the about us page
 * privacyPolicyLink: string - link to the privacy policy page
 * termsOfUseLink: string - link to the terms of use page
 * termsOfSaleLink: string - link to the terms of sale page
 */
export type TFooterConfig = {
  termsOfUseLink: string;
  termsOfSaleLink: string;
  aboutUsLink: string;
  privacyPolicyLink: string;
};
export const APP_USER_FOOTER_CONFIG
  = new InjectionToken<string[]>('app.user.footer.config');

/**
 * APP_USER_FOOTER_CONFIG_PROVIDER
 *
 * Provider for the footer configuration
 * Add the footer configuration for the different languages here
 */
export const APP_USER_FOOTER_CONFIG_PROVIDER = (): Provider => ({
  provide: APP_USER_FOOTER_CONFIG,
  useValue: new Map<string, TFooterConfig>([
    ['en-US', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_US/TermsOfUse.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_US/TermsOfSale.pdf',
      aboutUsLink: 'https://www.heidelberg.com/us/en/company/about_us/company_profile/company_profile.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['de-DE', {
      termsOfUseLink: 'https://www.heidelberg.com/global/media/de/global_media/company___about_us/terms_and_conditions/terms_of_sale_and_delivery_hd.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/de_DE/Servicebedingungen%20HDD.pdf',
      aboutUsLink: 'https://www.heidelberg.com/de/de/index.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/de/media/local_media/ueber_uns/pdf/HDD_Datenschutzinformationen_20200309.pdf',
    }],
    ['de-AT', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/de_AT/AT_servicebedingungen.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/de_AT/AT_verkaufs_lieferbedingungen.pdf',
      aboutUsLink: 'https://www.heidelberg.com/at/de/index.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/de/global_content/privacy_statement.jsp',
    }],
    ['en-CA', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_CA/TermsOfUse.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_CA/TermsOfSale.pdf',
      aboutUsLink: 'https://www.heidelberg.com/ca/en/company/about_us_1/company.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['fr-CA', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/fr_CA/TermsOfUse.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/fr_CA/TermsOfSale.pdf',
      aboutUsLink: 'https://www.heidelberg.com/ca/fr/company/about_us_1/company.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/fr/global_content/privacy_statement.jsp',
    }],
    ['de-CH', {
      termsOfUseLink: '',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/de_CH/general_terms_and_conditions.pdf',
      aboutUsLink: 'https://www.heidelberg.com/ch/de/index.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/de/global_content/privacy_statement.jsp',
    }],
    ['fr-CH', {
      termsOfUseLink: '',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/fr_CH/general_terms_and_conditions.pdf',
      aboutUsLink: 'https://www.heidelberg.com/ch/fr/index.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/fr/global_content/privacy_statement.jsp',
    }],
    ['id-ID', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/id_ID/Terms_of_Use_HID.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/id_ID/Terms_of_Sale_HID.pdf',
      aboutUsLink: 'https://www.heidelberg.com/id/in/about_us/about_us_2/content_page.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['en-MY', {
      termsOfUseLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_MY/OLS-advantage_terms_malaysia.pdf',
      aboutUsLink: 'https://www.heidelberg.com/my/en/company_news/about_us/about_us_1/about_us.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['en-PH', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_PH/HPH_Term_of_Use_Final.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_PH/HPH_Terms_Conditions_final.pdf',
      aboutUsLink: 'https://www.heidelberg.com/ph/en/about_us/overview_3/about_us_overview.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['pl-PL', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/pl_PL/Warunki_Sprzedazy_Materialow_2022.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/pl_PL/Warunki_swiadczenia_uslug_serwisowych.pdf',
      aboutUsLink: 'https://www.heidelberg.com/pl/pl/company/about_us_1/about_us/about_us_1.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/pl/pl/company/privacy_statement.jsp',
    }],
    ['th-TH', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/th_TH/Terms_of_Use_HTH.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/th_TH/Terms_of_Sale_HTH.pdff',
      aboutUsLink: 'https://www.heidelberg.com/th/th/about_us_/about_us/about_heidelberg_thailand.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['en-TH', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_TH/Terms_of_Use_HTH.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_TH/Terms_of_Sale_HTH.pdf',
      aboutUsLink: 'https://www.heidelberg.com/th/th/about_us_/about_us/about_heidelberg_thailand.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
    ['fr-FR', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/fr_FR/Conditions_utilisation_HFR.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/fr_FR/CGV-20170430.pdf',
      aboutUsLink: 'https://www.heidelberg.com/fr/fr/index.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/fr/global_content/privacy_statement.jsp',
    }],
    ['en-SG', {
      termsOfUseLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_SG/HSG_Terms_of_Use_and_Sale.pdf',
      termsOfSaleLink: 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/en_SG/HSG_Terms_of_Use_and_Sale.pdf',
      aboutUsLink: 'https://www.heidelberg.com/sg/en/index.jsp',
      privacyPolicyLink: 'https://www.heidelberg.com/global/en/global_content/privacy_statement.jsp',
    }],
  ])
});
