/** stage environment on https://reward-engine.neo.stage.heidelberg.cloud/ */
export const environment = {
  production: true,
  // FOR CAS: fb00e95bcad346e29ff16c670e762153
  // FOR HPLUS: NmgqR9ClH3BXOwJ3yBE4SGYAXYig58mILUG1SDso
  secret: 'NmgqR9ClH3BXOwJ3yBE4SGYAXYig58mILUG1SDso',
  // FOR CAS: 8d1534e98fde40b4b7df93ccbe72af05-oidc
  // FOR HPLUS: 385bec04-424e-43b3-9196-48c5857d198f
  casClientId: '385bec04-424e-43b3-9196-48c5857d198f',
  redirectUri: 'https://reward-engine.neo.stage.heidelberg.cloud/',
  casBaseUrl: 'https://login.stage.heidelberg.com',
  // For CAS: /cas/logout?service=
  // FOR HPLUS: /hpluscheck/oauth/logout?service=
  idmLogoutUrl: '/hpluscheck/oauth/logout?service=',
  // For CAS: /cas/oidc/token
  // FOR HPLUS: /hpluscheck/oauth/access_token
  idmAccessTokenUrl: '/hpluscheck/oauth/access_token',
  // For CAS: /cas/oidc/authorize?response_type=code
  // FOR HPLUS - community: /hpluscheck/oauth/authorize/?use_upn_for_sub=false&response_type=code
  // FOR HPLUS - admin: /hpluscheck/oauth/authorize/?only_cas=true&response_type=code
  idmRedirectUri: '/hpluscheck/oauth/authorize/?use_upn_for_sub=true&response_type=code',
  idmAdminRedirectUri: '/hpluscheck/oauth/authorize/?only_cas=true&response_type=code',
  publicUrls: ['/', '/overview'],
  backendUrl: 'https://reward-engine.neo.stage.heidelberg.cloud',
  shopUrl: 'https://heidelberg--uat.sandbox.my.site.com/closedeshop/',
  rewardTokenStorageKey: 'REWARD_TOKEN_STORAGE_KEY',
  rewardUserStorageKey: 'REWARD_USER_STORAGE_KEY',
  adminRedirectUrl: 'https://reward-engine.neo.stage.heidelberg.cloud/hdmadmin/',
  enableLog: true
};
