import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UserStatsService } from '../../../services/user-stats/user-stats.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserRoleSettingsComponent } from '../user-role-settings/user-role-settings.component';
import { UserAccountSwitchComponent } from '../user-account-switch/user-account-switch.component';
import {CommunityUserService, UserContext} from '@neo-reward-engine-web/ecom-api';
import {Subject, Subscription} from "rxjs";
import {RedirectModalComponent} from "../redirect-modal/redirect-modal.component";

export type LoginButtonState = 'LOGIN' | 'LOGOUT';
/**
 * user info login flyout in desktop header
 */
@Component({
  selector: 'neo-reward-engine-web-user-info-login',
  templateUrl: './user-info-login.component.html',
  styleUrls: ['./user-info-login.component.scss'],
})
export class UserInfoLoginComponent implements OnInit, OnDestroy {
  private _userState: UserContext | null = null;
  private _logout$ = new EventEmitter<void>();
  private _login$ = new EventEmitter<void>();
  private _loginState: LoginButtonState = 'LOGOUT';

  private modalOptions: NgbModalOptions = { centered: true, size: 'md' };

  private close$ = new Subject<boolean>();
  private closeSubs: Subscription | undefined;
  private _closeFired$ = new EventEmitter<void>();

  /**
   *
   * @param modalService injected to open settings as a modal (e.g. account switch and role settings)
   * @param userStatsService injected to make user account stats available to template via getters
   */
  constructor(
    private modalService: NgbModal,
    private userStatsService: UserStatsService,
  ) {
    //
  }

  ngOnInit(): void {
    this.closeSubs = this.close$.subscribe(value => {
      if (value) {
        this._closeFired$.emit();
      }
    })
  }

  @Input()
  set userState(value: UserContext | null) {
    if (value) {
      this._loginState = 'LOGIN';
    } else {
      this._loginState = 'LOGOUT';
    }
    this._userState = value;
  }
  get userState(): UserContext | null {
    return this._userState;
  }

  get userBalance$() {
    return this.userStatsService.userBalance$;
  }
  get relatedAccounts(): number {
    return this.userState?.userInfo?.relatedAccounts?.length || 0;
  }
  get loginState(): LoginButtonState {
    return this._loginState;
  }
  @Output()
  get logout$(): EventEmitter<void> {
    return this._logout$;
  }
  @Output()
  get login$(): EventEmitter<void> {
    return this._login$;
  }

  @Output()
  get closeFired$(): EventEmitter<void> {
    return this._closeFired$;
  }

  /**
   * handles login / logout interaction
   * @param state state of the loggin button (login || logout)
   */
  public onLoginClick(state: LoginButtonState): void {
    if (state === 'LOGIN') {
      this._logout$.emit();
    } else {
      this._login$.emit();
    }
  }
  /**
   * template triggered to open the uer role settings modal
   */
  openUserRoleSettings() {
    this.modalService.open(UserRoleSettingsComponent, this.modalOptions);
  }
  /**
   * template triggered to open the effective account switch modal
   */
  openEffectiveAccountSwitcher() {
    if (this.relatedAccounts > 1) {
      const modalRef = this.modalService.open(
        UserAccountSwitchComponent,
        this.modalOptions
      );
      modalRef.componentInstance.userContext = this.userState;
      modalRef.componentInstance.close$ = this.close$;
    }
  }

  openRedirectModal(){
    const modalRef = this.modalService.open(RedirectModalComponent,this.modalOptions);
    modalRef.componentInstance._redirectType = 'REGISTER';
  }

  ngOnDestroy(): void {
    this.closeSubs?.unsubscribe();
  }
}
