import { Component } from '@angular/core';
import { LoadIndicatorService } from '../../services/load-indicator/load-indicator.service';

/**
 * Central Loading Spinner Component
 * TODO: use prettier design from eshop spinner
 */
@Component({
  selector: 'neo-reward-engine-web-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  /**
   *
   * @param loadIndicatorService injeted to know if the spinner is vissible or not
   */
  constructor(public readonly loadIndicatorService: LoadIndicatorService) {
    //
  }
}
