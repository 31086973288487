<div *transloco="let t;">
  <p class="text--bold pt-4">{{ t('header.userInfoLogin.myAccountFlyOutHeadline') }}</p>
  <ng-container *ngIf="userState?.userInfo?.userType !== 'Community'">
    <p>{{ t('header.userInfoLogin.myAccountFlyOutSub') }}</p>
    <ul class="pl-4 header__benefitsList">
      <li>{{ t('header.userInfoLogin.myAccountFlyOutPoint1') }}</li>
      <li>{{ t('header.userInfoLogin.myAccountFlyOutPoint2') }}</li>
      <li>{{ t('header.userInfoLogin.myAccountFlyOutPoint3') }}</li>
    </ul>
  </ng-container>
  <ng-container *ngIf="userState?.userInfo?.userType === 'Community'">
    <div class="text--bold mt-2" (click)="openEffectiveAccountSwitcher()" [ngClass]="{'effectiveAccountSwitch': relatedAccounts > 1}">{{t('shared.welcome')}} {{userState?.userInfo?.accountName}}</div>
    <div class="mt-3 mb-3">
      <img  src = "../../../../assets/images/Certificate.svg" style = "top:-1px;">
      {{(userBalance$ | async)?.pointBalance}} {{t('shared.rewardPoints')}}
    </div>
    <div class="text--highlight mt-3">
      <img *ngIf = "userState?.userInfo?.isMainContact" (click) = "openUserRoleSettings()" src = "../../../../assets/images/Gear_Wheel.svg" style = "cursor: pointer;padding-bottom: 3px;"> <span (click) = "openUserRoleSettings()" style = "cursor: pointer;">{{t('shared.userMgmt')}} </span>
    </div>
  </ng-container>
  <a class="header__logInBtn btn btn-lg d-block mt-3" (click)="onLoginClick(loginState)">
    <ng-container *ngIf="loginState === 'LOGOUT'; else logoutButtonTemplate">
      {{ t('shared.loginButtonLabel') }}
    </ng-container>
    <ng-template #logoutButtonTemplate>
      {{t('shared.logoutButtonLabel')}}
    </ng-template>
  </a>
  <div *ngIf="loginState === 'LOGOUT'" class="mobileNavigation__flyout__bottom">
    <p class="mb-0 mt-20 text--highlight--bold">{{ t('header.userInfoLogin.myAccountFlyOutNoAccount') }}</p>
    <a (click)="openRedirectModal()" class="mb-3 header__popUp__link text--hightlight--bold">{{ t('header.userInfoLogin.myAccountFlyOutRegister')
      }}</a>
  </div>
</div>
