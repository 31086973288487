import { Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  CartItemDto,
  CommunityUserService,
  Redeem,
  RewardProductDto,
  RedeemRequest,
  Address
} from '@neo-reward-engine-web/ecom-api';
import { EnvironmentService } from '../environment/environment.service';

/**
 * handles all things regarding the shopping cart
 */
@Injectable({
  providedIn: 'root',
})
export class RedemptionCartDataService implements OnDestroy{
  private static _redeemResponse$ = new BehaviorSubject<Redeem | undefined>(
    undefined
  );
  private static _redeemSimulateResponse$ = new BehaviorSubject<
    Redeem | undefined
  >(undefined);
  private static _redeemCartItems = new Array<CartItemDto>();

  private readonly myRedemptionCartLocalStorageKey: string = 'MyRedemptionCart';

  subscriptions: Subscription = new Subscription();

  constructor(
    private readonly logger: NGXLogger,
    private readonly communityUserService: CommunityUserService,
    private readonly environmentService: EnvironmentService
  ) {
    /** tries to load a cart from local storage if there is one */
    const localCart = localStorage.getItem(
      this.myRedemptionCartLocalStorageKey
    );

    if (this.redeemCartItems.length == 0 && localCart) {
      const localCartAsObject: CartItemDto[] = JSON.parse(localCart);
      this.redeemCartItems = localCartAsObject;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get cartItemsCount(): number {
    if (RedemptionCartDataService._redeemCartItems) {
      let count = 0;
      for (const item of RedemptionCartDataService._redeemCartItems) {
        count += item.quantity;
      }
      return count;
    } else {
      return 0;
    }
  }

  get redeemCartItems(): CartItemDto[] {
    return RedemptionCartDataService._redeemCartItems;
  }
  private set redeemCartItems(cartItems: CartItemDto[]) {
    localStorage.setItem(
      this.myRedemptionCartLocalStorageKey,
      JSON.stringify(cartItems)
    );
    RedemptionCartDataService._redeemCartItems = cartItems;
  }

  get redeemSimulateResponse$(): Observable<Redeem | undefined> {
    return RedemptionCartDataService._redeemSimulateResponse$;
  }
  get redeemResponse$(): Observable<Redeem | undefined> {
    return RedemptionCartDataService._redeemResponse$;
  }
  /** resets the state of the cart in the application */
  public resetCartObservables(): void {
    RedemptionCartDataService._redeemResponse$ = new BehaviorSubject<
      Redeem | undefined
    >(undefined);
    RedemptionCartDataService._redeemSimulateResponse$ = new BehaviorSubject<
      Redeem | undefined
    >(undefined);
    this.logger.debug('Cart has been reset.');
  }
  /** adds or removes product to or from cart  */
  public rewardProductToCart(
    rewardProduct: RewardProductDto,
    count: number
  ): void {
    // check if rewardProduct is even valid -> has id
    if (rewardProduct.id) {
      const cartItem: CartItemDto = { product: rewardProduct, quantity: count };

      // add to/remove from real cart
      this.redeemCartItems = this.addOrRemoveFromCart(
        RedemptionCartDataService._redeemCartItems,
        cartItem
      );
      this.logger.debug(
        'Item has been added or removed from cart:\n Count: ' +
          count +
          ' Item: (below)'
      );
      this.logger.debug(cartItem);
    }
  }
  /** calculates locally how many points the cart is worth instead of sending a request already */
  public calculatePointsLocal(): number {
    let points = 0;
    for (const item of RedemptionCartDataService._redeemCartItems) {
      points += item.product.points * item.quantity;
    }
    return points;
  }

  public refreshProductDtoModels(): void {
    const newModels: CartItemDto[] = [];
    const copyToIterate: CartItemDto[] = JSON.parse(
      JSON.stringify(RedemptionCartDataService._redeemCartItems)
    );
    for (const item of copyToIterate) {
      if (item.product.id) {
        this.subscriptions.add(this.communityUserService
          .getRewardProductById(item.product.id)
          .subscribe((newItem) => {
            RedemptionCartDataService._redeemCartItems = JSON.parse(
              JSON.stringify(newModels)
            );
          }));
      }
    }
  }
  /** checks if cart can be redeemed as is (is valid) */
  public simulateRedemptionCart(): Observable<Redeem | undefined> {
    this.subscriptions.add(this.communityUserService
      .redeemSimulate(
        RedemptionCartDataService._redeemCartItems
      )
      .subscribe({
        next: (response) => {
          RedemptionCartDataService._redeemSimulateResponse$.next(response);
          this.logger.debug(
            'Redemption Cart Simulation successfull: ' + response
          );
        },
        error: (error) => {
          RedemptionCartDataService._redeemSimulateResponse$.next(error);
          this.logger.error(
            'Redemption Cart Simulation has thrown an error: ' + error
          );
        }
      }));
    return RedemptionCartDataService._redeemSimulateResponse$;
  }
  /** finally redeems cart as is */
  public redeemRedemptionCart(_billingAddress: Address, _additionalInformation: string): Observable<Redeem | undefined> {
    this.subscriptions.add(this.communityUserService
      .redeem(
        { cartItems: RedemptionCartDataService._redeemCartItems, billingAddress: _billingAddress, additionalInformation: _additionalInformation } as RedeemRequest
      )
      .subscribe({
        next: (response) => {
          RedemptionCartDataService._redeemResponse$.next(response);
          if (response.success) {
            RedemptionCartDataService._redeemCartItems = [];
            localStorage.removeItem(this.myRedemptionCartLocalStorageKey);
            this.logger.debug(
              'Redemption Cart Redemption successfull: ' + response
            );
          }
        },
        error: (error) => {
          RedemptionCartDataService._redeemResponse$.next(error);
          this.logger.error(
            'Redemption Cart Redemption has thrown an error: ' + error
          );
        }
      }));
    return RedemptionCartDataService._redeemResponse$;
  }
  /** handles logic of redeeming or adding an item to cart based on positive or negative number */
  private addOrRemoveFromCart(
    cartItems: CartItemDto[],
    cartItem: CartItemDto
  ): CartItemDto[] {
    const ifExists = cartItems.find(
      (item) => item.product.id == cartItem.product.id
    );
    if (
      ifExists &&
      (ifExists.quantity + cartItem.quantity <= 0 || cartItem.quantity == 0)
    ) {
      cartItems.splice(cartItems.indexOf(ifExists), 1);
    } else if (ifExists && ifExists.quantity + cartItem.quantity > 0) {
      ifExists.quantity += cartItem.quantity;
    } else if (!ifExists && cartItem.quantity > 0) {
      cartItems.push(cartItem);
    }

    return cartItems;
  }
}
