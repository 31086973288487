export * from './adminUser.service';
import { AdminUserService } from './adminUser.service';
export * from './adminWidgetContent.service';
import { AdminWidgetContentService } from './adminWidgetContent.service';
export * from './asset.service';
import { AssetService } from './asset.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './communityUser.service';
import { CommunityUserService } from './communityUser.service';
export * from './eShop.service';
import { EShopService } from './eShop.service';
export * from './replicator.service';
import { ReplicatorService } from './replicator.service';
export * from './superAdminUser.service';
import { SuperAdminUserService } from './superAdminUser.service';
export const APIS = [AdminUserService, AdminWidgetContentService, AssetService, AuthService, CommunityUserService, EShopService, ReplicatorService, SuperAdminUserService];
