/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectItem } from './collectItem';


export interface CollectCart { 
    ssu: string;
    contactId: string;
    accountId: string;
    currencyCode: string;
    orderId?: string | null;
    items: Array<CollectItem>;
}

