/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleLabel } from './ruleLabel';


export interface AdminAppDefaults { 
    roundingRules: Array<string>;
    rewardProductCategories: Array<string>;
    ruleStatuses: Array<string>;
    ruleActions: Array<RuleLabel>;
    ruleModes: Array<RuleLabel>;
    ruleTypes: Array<RuleLabel>;
    pointExpiry: Array<number>;
    pointExpiryReminderPeriod: Array<number>;
    tierResetPeriods: Array<number>;
}

