/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RewardTransactionDto } from './rewardTransactionDto';


export interface UserBalanceTransactions { 
    from: string;
    to: string;
    transactions: Array<RewardTransactionDto>;
    pointBalance: number;
    earned: number;
    redeemed: number;
    lastUpdate: string;
}

