/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmailTemplateType = 'Order' | 'AccountWaitingForApproval' | 'AccountAccepted' | 'AccountDeclined' | 'TierLevelAchieved' | 'ChangePointBalance' | 'ExpiringPoints' | 'NewPromotion' | 'NewTierSeasonInfo';

export const EmailTemplateType = {
    Order: 'Order' as EmailTemplateType,
    AccountWaitingForApproval: 'AccountWaitingForApproval' as EmailTemplateType,
    AccountAccepted: 'AccountAccepted' as EmailTemplateType,
    AccountDeclined: 'AccountDeclined' as EmailTemplateType,
    TierLevelAchieved: 'TierLevelAchieved' as EmailTemplateType,
    ChangePointBalance: 'ChangePointBalance' as EmailTemplateType,
    ExpiringPoints: 'ExpiringPoints' as EmailTemplateType,
    NewPromotion: 'NewPromotion' as EmailTemplateType,
    NewTierSeasonInfo: 'NewTierSeasonInfo' as EmailTemplateType
};

