<div class="cartItems__listRow" *transloco="let t;">
  <div class="row h-100 no-gutters align-items-center justify-content-start position-relative py-2 pr-2 pr-lg-0 ">

    <div class="col-12 col-md-6 order-1 my-2">
      <div class="row no-gutters align-items-center justify-content-start position-relative pl-md-0"
        style="height: 90px;">
        <div class="col-4 col-lg-2 d-md-block order-1 text-center">
          <img src="{{ rewardProduct.product.productImages?.[0] || '/assets/images/no-image.jpg'}}"
            style="max-width: 90px; max-height: 90px" alt="Responsive image">
        </div>
        <div class="col-8 col-lg-10 order-3 text--bold px-3">
          <p class="cartItems__status" *ngIf="userContext$ | async; let userContext;">
            {{ localeNameOfProductItem(rewardProduct.product, userContext.userInfo!.language!) }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-2 order-2 my-2">
      <div class="row no-gutters align-items-center justify-content-start position-relative">
        <div class="col-6 d-md-none text--bold my-0">
          <p>
            {{t('redemptionCart.articleDetailList.header.quantity')}}:
          </p>
        </div>
        <div class="col-4 col-md">
          <input *ngIf="!disableEdit" type="number" class="form-control" [ngModel]="rewardProduct.quantity" max="2147483647"
            (change)="sendCartItemChange($event);" min="0" width="100%">
          <span *ngIf="disableEdit">{{rewardProduct.quantity}}</span>
        </div>
        <div class="col-2 d-lg-block col-md-6 ">
        </div>
      </div>
    </div>

    <div class="col-12 col-md-2 order-3 my-2">
      <div class="row no-gutters align-items-center justify-content-start position-relative">
        <div class="col-6 d-md-none text--bold my-0">
          {{t('redemptionCart.articleDetailList.header.price')}}:
        </div>
        <div class="col-6 col-md-12 my-0">
          <p class="cartItems__status">
            {{ rewardProduct.product.points}} {{t('redemptionCart.points')}}
          </p>
          <p *ngIf="showCoPayment">
            + {{ nonCashBenefitOfProductItem(rewardProduct.product) |currency: currencyCode :'symbol':
            '1.2-2':currencyLocale}}
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-2 order-4 my-2">
      <div class="row no-gutters align-items-center justify-content-start position-relative">
        <div class="col-6 d-md-none text--bold my-0">
          {{t('redemptionCart.articleDetailList.header.subtotal')}}:
        </div>
        <div class="col-4 col-md-8 ">
          <p class="cartItems__status ">
            {{ ((rewardProduct.product.points || 0)*(rewardProduct.quantity||0)) || 0 }}
            {{t('redemptionCart.points')}}
          </p>
          <p *ngIf="showCoPayment">
            + {{ ( nonCashBenefitOfProductItem(rewardProduct.product) * (rewardProduct.quantity || 0) ) |currency: currencyCode :'symbol':
            '1.2-2':currencyLocale}}
          </p>
        </div>
        <div class="col-2 d-md-block col-md-4">
          <button *ngIf="!disableEdit" class="btn-invisible"
            (click)="rewardCartService.rewardProductToCart(rewardProduct!.product, 0)">
            <i class="icon-recycle_bin"></i>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
