/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RelatedAccount } from './relatedAccount';
import { UserType } from './userType';


export interface RewardUser { 
    id?: string | null;
    userType: UserType;
    contactName?: string | null;
    logonId?: string | null;
    email?: string | null;
    isSuperAdmin?: boolean | null;
    contactId?: string | null;
    language?: string | null;
    /**
     * Admin user related SSUs
     */
    relatedSsu?: Array<string> | null;
    relatedAccounts?: Array<RelatedAccount> | null;
}

