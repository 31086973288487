import { ProgramSettingsService } from './../program-settings/program-settings.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Menu, menuConfiguration, NavigationArea } from './menu.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '@neo-reward-engine-web/auth';
import { Router } from '@angular/router';
import {
  CommunityUserService,
  UserContext,
} from '@neo-reward-engine-web/ecom-api';

/**
 * menu service that not only builds the menu data but also handles the different viewport sizing
 */
@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private _menuConfig = menuConfiguration;
  private _menuConfig$ = new BehaviorSubject<Menu>({
    navigationArea: 'PUBLIC',
    menuItems: [
      {
        label: 'menuBar.navLinkPointRegister',
        routerTarget: '/',
      },
    ],
  } as Menu);
  private loggedIn: boolean;
  private registered: boolean;
  private _canRedeem$ = new BehaviorSubject<boolean | null | undefined>(null);
  private userContextSub = new BehaviorSubject<UserContext | null>(null);

  public hoverAccount = false;
  public hoverShoppingList = false;
  public hoverShoppingCart = false;
  public mobileMenuToggle = false;
  public mobileMenuToggleMyAccount = false;
  public isNavFoldedIn = false;
  public flyoutHeight: number | undefined;

  subscriptions: Subscription = new Subscription();

  /**
   *
   * @param userService injected to retrieve usertype and status to build menu points accordingly
   * @param router
   * @param communityUserService injected to retrieve the canredeem permission of the user
   * @param programSettingsService
   */
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly communityUserService: CommunityUserService,
    private readonly programSettingsService: ProgramSettingsService
  ) {
    this.loggedIn =
      this.userService.userState$.value?.userType === 'Community' ||
      this.userService.userState$.value?.userType === 'Admin';

    this.registered = this.userService.userState$.value?.status === 'Enabled';

    this.retrieveCanRedeemState();
  }

  ngOnDestroy(): void {
    this.userContextSub.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  get menuConfig$() {
    return this._menuConfig$;
  }

  get isLoggedIn() {
    return this.loggedIn;
  }

  get isRegistered() {
    return this.registered;
  }

  get getIsNavFoldedIn() {
    return this.isNavFoldedIn;
  }

  get isInCheckout(): boolean {
    return this.router.url.includes('checkout');
  }

  get isInCart(): boolean {
    return this.router.url.includes('cart');
  }

  get canRedeem$() {
    return this._canRedeem$;
  }

  public setMenuItems(navigationArea: NavigationArea): void {
    const navArea = this._menuConfig.get(navigationArea);
    if (navArea !== undefined) {
      // TODO Tier system filter is not reactive this could cause a race condition
      // TODO Consider to use a pipe with subscription in order to await arrival of Program Settings
      if(this.programSettingsService.programSettings$.value?.tierSystem.enabled){
        this._menuConfig$.next(navArea);
      }else{
        const navWithoutTiers = {
          navigationArea: navArea.navigationArea,
          menuItems: navArea.menuItems.filter(x => !x.routerTarget.includes("tier"))
        };
        this._menuConfig$.next(navWithoutTiers);
      }
    }
  }

  public resizeFlyoutMobile(innerHeight: number) {
    if (window.innerWidth < 768) {
      this.flyoutHeight = innerHeight - 60;
    } else {
      this.flyoutHeight = innerHeight - 202;
    }
  }

  public getRouterTranslation(prefix: string): string {
    switch (this.router.url) {
      case '/rewards': {
        return prefix + 'rewards';
      }
      case '/reward-tier-status': {
        return prefix + 'rewardTierStatus';
      }
      case '/reward-point-history': {
        return prefix + 'rewardPointHistory';
      }
      default: {
        return prefix + 'overview';
      }
    }
  }

  retrieveCanRedeemState() {
    if (this.loggedIn) {
      this.subscriptions.add(this.communityUserService
        .userContext()
        .subscribe((context) =>
          this._canRedeem$.next(context.userInfo?.canRedeem)
        ));
    }
  }
}
