/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RewardAccount } from './rewardAccount';
import { RewardUser } from './rewardUser';
import { UserInfo } from './userInfo';


export interface UserContext { 
    userInfo?: UserInfo;
    rewardAccount?: RewardAccount;
    /**
     * List of RewardUsers of current RewardAccount
     */
    rewardUsers?: Array<RewardUser> | null;
}

