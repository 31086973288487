<div *transloco="let t" class="text--blue pt-3">

    <div class="col-12  px-0" ngbAutofocus>
        <div class=" d-inline-block">
            <div class="h1 h1--bold mb-4 mb-md-0 pr-0 d-block">
                {{t('header.userInfoLogin.userRoleSettings.responsibles')}}
            </div>
        </div>
        <i (click)="close()" style="cursor: pointer;float:right;" class="icon icon-close"></i>
    </div>


    <div class="row">
        <div class="col-12" style="flex-wrap: wrap;">
            {{t('header.userInfoLogin.userRoleSettings.responsiblesTxt')}} <br>
            <div class="mt-1">{{t('header.userInfoLogin.userRoleSettings.responsiblesNote1')}} (<img src = "../../../../assets/images/Gear_Wheel.svg" style = "position: relative;top:-2px;">) {{t('header.userInfoLogin.userRoleSettings.responsiblesNote2')}}</div>
        </div>
    </div>
    <hr>

    <div class="row">
        <div class=" col-6 h3 h3--bold"> {{t('header.userInfoLogin.userRoleSettings.name')}}</div>
        <div class="col-6 h3 h3--bold text-center"> {{t('header.userInfoLogin.userRoleSettings.rightToRedeem')}}</div>
    </div>

    <div style="max-height: 200px;" [ngClass]="{'scrollable': userPermissionDtos.length > 6}">
        <div class="row ml-2" *ngFor="let user of userPermissionDtos;let i = index;" [formGroup]="formResponsibles">
            <div class="col-6 mt-2 pl-0">
                <div> {{user.contactName}}</div>
            </div>
            <div class="col-6 mt-2 custom-control custom-checkbox text-center">
                <input #value id={{i}} name={{i}} type="checkbox" formControlName={{i}} class="custom-control-input"
                    (change)="onCheckBoxChange(value.checked,i)">
                <label class="custom-control-label" for={{i}}>
                </label>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12 d-flex " style="justify-content:flex-start">
            <button (click)="onSubmit()" class="btn"
            style="margin-right: 10px !important;margin-left:unset!important;width: 100px">{{t('header.userInfoLogin.userRoleSettings.submit')}}</button>
            <button (click)="close()" class="btn btn-prim-outline"
                style="margin-right: 10px !important;margin-left:unset !important;width:100px;">{{t('header.userInfoLogin.userRoleSettings.cancel')}}</button>
        </div>


    </div>


</div>
