<div class="ml-auto" *transloco="let t;">
    <div class="row">
        <div class="mx-auto mr-lg-5  h5 text-white" style ="font-family: 'Heidelberg Gothic-18' ;">{{t('header.miniDashboard.infoColumn.availablePoints')}}</div>
    </div>
    <div class="row">
        <div class="mx-auto mr-lg-5 h2 h2--bold text-white" style="line-height: initial;">
            <i class="icon icon-reward align-middle text-white mini-dashboard__progressInfoFontSize"></i>{{(userBalance$ | async)?.pointBalance}}
        </div>
    </div>
    <div class="row mt-3 mt-sm-0" *ngIf="(userBalance$ | async) as update">
        <div class="mx-auto text-center text-md-right mr-lg-5 font-italic text-white font-weight-light">
            <span class="d-inline-block">{{t('header.miniDashboard.infoColumn.lastUpdate')}}&nbsp;</span>
            <span class="d-inline-block">{{getDateTime(update.lastUpdate)}}</span>
        </div>
    </div>
    <div class="row">
        <div class="mx-auto mr-lg-5">&nbsp;</div>
    </div>
</div>