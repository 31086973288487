import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserState } from './user.state';
import {AccessToken} from "./access.token";
import {AuthConfigService} from "./auth-config.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _userState$ = new BehaviorSubject<UserState | null>(null);
  private _token$ = new BehaviorSubject<string | null>(null);
  private _has401 = false;

  constructor(
    private readonly autConfigService: AuthConfigService,
  ) {}

  get userState$(): BehaviorSubject<UserState | null> {
    return this._userState$;
  }

  get token$(): BehaviorSubject<string | null> {
    return this._token$;
  }

  get has401(): boolean {
    return this._has401;
  }

  set has401(value: boolean) {
    this._has401 = value;
  }

  public upsertToken(token: AccessToken, needsDehydration = true): void {
    if (needsDehydration) {
      sessionStorage
        .setItem(this.autConfigService.config.rewardTokenStorageKey, JSON.stringify(token));
    }
    this._token$.next(token.accessToken);
  }

  public upsertUserState(userState: UserState, needsDehydration = true): void {
    if (needsDehydration) {
      sessionStorage
        .setItem(this.autConfigService.config.rewardUserStorageKey, JSON.stringify(userState));
    }
    this._userState$.next(userState);
  }

  public logout(): void {
    sessionStorage.clear();
    this._token$.next(null);
    this._userState$.next(null);
  }
}
