
<div class="outerCard">

    <div class="row align-items-center m-0 topPanel ">

      <div class = "col-4">
        <button class=" align-items-center h-100 prevYearButton" (click)="decrementYear()">
          <i class=" icon-collapse_left"></i>
        </button>
      </div>

      <div class = "col-4">
        <p class="yearLabel m-0"  style = "font-size: 20px;">{{ years[currentYearIndex] }}</p>
      </div>

      <div class = "col-4">
        
      <button class="h-100 align-items-center nextYearButton" (click)="incrementYear()">
        <i class=" p-0 icon-collapse_right"></i>
      </button>
      </div>
    
 
  
    </div>
    
    <div class="contentPanel">
      <div >
        <div 
        (click)="onClick(i)" 
        class="monthItem" 
        *ngFor="let month of monthDataSlice; let i = index"
        [ngClass]="{ isEdge: rangeIndexes[0]===globalIndexOffset+i || rangeIndexes[1]===globalIndexOffset+i}">
            <div 
            (mouseover)="setHoverProperty($event,month,i)" 
            [ngClass]="{ 
            inRange: month.isInRange, 
            isLowerEdge: month.isLowerEdge, 
            isUpperEdge: month.isUpperEdge,
            isHovered: month.isHovered}">
            {{ month.monthName }}
          </div>
        </div>
    </div>
    </div>

  </div>