<div *transloco="let t">
  <div class="py-3">
    <div *ngIf="rewardCartService.redeemCartItems === undefined || rewardCartService.redeemCartItems.length === 0 || !isLoggedIn || !isRegistered; else showItems">
      <p class="text--bold mb-0">{{ t('myCartFlyOutHeadline') }}</p>
      <div class="text-center">
        <p>{{ t('myCartFlyOutNoItems') }}</p>
      </div>
    </div>
    <ng-template #showItems>
      <div class="row">
        <div class="col-12">
          <span class="text--bold">{{ t('myCartFlyOutHeadline') }}</span>
          <p class="mb-3">{{rewardCartService.cartItemsCount}} {{t('mobileMenu.miniCartItem')}}</p>
        </div>
      </div>
      <div class="container" style="max-height: 30vh; overflow-y: auto;">
        <div *ngFor="let item of rewardCartService.redeemCartItems" class="mt-0 header__miniCart__item">
          <div class="row align-items-center" style="height: 3em; line-height: 1.5em;">
            <div class="col-3">
              <img src="{{item?.product?.productImages?.[0] || '../../../../../../../assets/images/no-image.jpg'}}"
                style="max-height: 3em; max-width: 3em;"/>
            </div>
            <div class="col my-auto text-left" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"  *ngIf="userContext$ | async; let userContext;">
              
              <span class="text--bold" >{{item?.quantity || 0}}x {{ localeNameOfProductItem(item.product, userContext.userInfo!.language!) }}</span><br>
              <span>{{((item?.quantity || 0) * (item?.product?.points || 0)) || 0}} {{t('shared.pointsShortHand')}}</span>
            </div>
            <div class="col-2 my-auto text-right">
              <i class="icon icon-recycle_bin" (click)="removeOneFromCart(item!.product)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mt-2 text--bold">
          <div class="col-6 text-left">
            {{t('redemptionCart.overviewInfo.totalAmount')}}
          </div>
          <div class="col-6 text-right">
            {{totalPointsCart}} {{t('shared.pointsShortHand')}}
          </div>
        </div>
        <div class="row mt-2 text--bold">
          <button class="btn btn-primary mb-0 w-100 mx-2" (click)="clickOnCart()">{{t('mobileMenu.toCart')}}</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>