<div id="footer" class="footer-ds" *transloco="let t;">

    <div class="container-fluid px-xl-0 footer-ds__positioning">
        <div class="row footer-ds__ssu-column">

            <div class="col-9 col-md-3" *ngIf="(menuInformation$ | async)?.menuItems; let menuInformation;">
                <p class="h5 h5--bold">{{t('footer.categoriesHeader')}}</p>
                <a class="footer-ds__ssu-column d-block" *ngFor="let menuPoint of menuInformation"
                    routerLink="{{menuPoint.routerTarget}}" queryParamsHandling="preserve">{{t(menuPoint.label)}}</a>
                <a *ngIf="(canRedeem$ | async) && isLoggedInAndRegistered" routerLink="/cart" queryParamsHandling="preserve">{{t('myCartFlyOutHeadline')}}</a>
            </div>

            <div class="col-9 col-md-3 order-12 order-md-1"
                *ngIf="footerConfig4ActiveLang">
                <p class="h5 h5--bold">{{t('footer.companyProfileHeader')}}</p>
                <a *ngIf="footerConfig4ActiveLang.termsOfUseLink !== ''" class="footer-ds__ssu-column d-block"
                    [href]="footerConfig4ActiveLang.termsOfUseLink"
                    target="_blank" rel="noopener">{{t('footer.termsOfService')}}</a>
                <a *ngIf="footerConfig4ActiveLang.termsOfSaleLink !== ''" class="footer-ds__ssu-column d-block"
                    [href]="footerConfig4ActiveLang.termsOfSaleLink"
                    target="_blank" rel="noopener">{{t('footer.termsOfSale')}}</a>
                <a *ngIf="footerConfig4ActiveLang.privacyPolicyLink !== ''" class="footer-ds__ssu-column d-block"
                    [href]="footerConfig4ActiveLang.privacyPolicyLink"
                    target="_blank" rel="noopener">{{t('footer.privacyPolicy')}}</a>
                <a *ngIf="footerConfig4ActiveLang.aboutUsLink !== ''" class="footer-ds__ssu-column d-block"
                   [href]="footerConfig4ActiveLang.aboutUsLink"
                   target="_blank" rel="noopener">{{t('footer.aboutUs')}}</a>
            </div>
            <ng-template #visitorCompanyProfile>
                <div class="col-9 col-md-3 order-12 order-md-1" *ngIf="footerConfig4ActiveLang">
                    <p class="h5 h5--bold">{{t('footer.companyProfileHeader')}}</p>
                    <a class="footer-ds__ssu-column d-block"
                        [href]="footerConfig4ActiveLang.termsOfUseLink"
                        target="_blank" rel="noopener">{{t('footer.termsOfService')}}</a>
                    <a class="footer-ds__ssu-column d-block"
                        [href]="footerConfig4ActiveLang.termsOfSaleLink"
                        target="_blank" rel="noopener">{{t('footer.termsOfSale')}}</a>
                    <a class="footer-ds__ssu-column d-block"
                        [href]="footerConfig4ActiveLang.privacyPolicyLink"
                        target="_blank" rel="noopener">{{t('footer.privacyPolicy')}}</a>
                    <a class="footer-ds__ssu-column d-block"
                        [href]="footerConfig4ActiveLang.aboutUsLink"
                        target="_blank" rel="noopener">{{t('footer.aboutUs')}}</a>
                </div>
            </ng-template>


            <div class="col-9 col-md-3 order-12 order-md-1">
                <p class="h5 h5--bold">{{t('footer.otherHeadline')}}</p>
                <a class="footer-ds__ssu-column d-block" (click)="openRedirectModal()" queryParamsHandling="preserve">{{t('footer.contactForm')}}</a>
            </div>

            <div class="col-3 text-right footer-icon-container order-1 order-md-12"
                *ngIf="(footerInformation$ | async); let footerInformation; else visitorSocialLinks;">

                <a *ngIf="footerInformation.socialMediaLinks?.twitterLink" href="{{footerInformation.socialMediaLinks?.twitterLink}}" target="_blank" rel="noopener">
                    <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_twitter"></i>
                </a>

                <a *ngIf="footerInformation.socialMediaLinks?.linkedInLink" href="{{footerInformation.socialMediaLinks?.linkedInLink}}" target="_blank" rel="noopener">
                    <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_linkedin"></i>
                </a>

                <a *ngIf="footerInformation.socialMediaLinks?.facebookLink" href="{{footerInformation.socialMediaLinks?.facebookLink}}" target="_blank" rel="noopener">
                    <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_facebook"></i>
                </a>

                <a *ngIf="footerInformation.socialMediaLinks?.youtubeLink" href="{{footerInformation.socialMediaLinks?.youtubeLink}}" target="_blank" rel="noopener">
                    <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_youtube"></i>
                </a>

                <a *ngIf="footerInformation.socialMediaLinks?.instagramLink" href="{{footerInformation.socialMediaLinks?.instagramLink}}" target="_blank" rel="noopener">
                    <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_instagram"></i>
                </a>
            </div>

            <ng-template #visitorSocialLinks>
                <div class="col-3 text-right footer-icon-container order-1 order-md-12">

                    <a href="https://twitter.com/HeidelbergNA" target="_blank" rel="noopener">
                        <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_twitter"></i>
                    </a>

                    <a href="https://www.linkedin.com/company/heidelbergnorthamerica" target="_blank" rel="noopener">
                        <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_linkedin"></i>
                    </a>

                    <a href="https://www.facebook.com/Heidelberg.NA" target="_blank" rel="noopener">
                        <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_facebook"></i>
                    </a>

                    <a href="https://www.youtube.com/user/Heidelguy" target="_blank" rel="noopener">
                        <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_youtube"></i>
                    </a>

                    <a href="https://www.instagram.com/heidelbergnorthamerica/" target="_blank" rel="noopener">
                        <i class="d-block d-md-inline-block mb-3 mr-md-2 icon-social_media_instagram"></i>
                    </a>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="subfooter">
    <p class="mb-0">© {{currentYear}} Heidelberger Druckmaschinen AG</p>
</div>
