import { Injectable } from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {UserService, UserState} from "@neo-reward-engine-web/auth";
import {AuthService} from "@neo-reward-engine-web/ecom-api";
import {environment} from "../../../../environments/environment";
import {loggerFn} from "../common-helper/pipe.helper";
import {tap} from "rxjs";
import {UserStatsService} from "../user-stats/user-stats.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AccountSwitchService {

  /**
   * ctor
   *
   * @param logger
   * @param userService
   * @param authService
   * @param userStatsService
   * @param router
   */
  constructor(
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly userStatsService: UserStatsService,
    private readonly router: Router

  ) { }

  public async switchAccount(
    effectiveAccount: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authService.refreshContext(effectiveAccount,).pipe(
        loggerFn(this.logger, 'AccountSwitchService.switchAccount'),
        tap(userInfo => this.userService.upsertUserState({
          sapAccountNumber: userInfo.sapAccountNumber,
          userType: userInfo.userType,
          ssu: userInfo.ssu,
          isMainContact: userInfo.isMainContact,
          status: userInfo.status,
          canRedeem: userInfo.canRedeem,
          relatedSsu: userInfo.relatedSsu,
          accountName: userInfo.accountName,
          contactName: userInfo.contactName,
          isAuthenticated: true,
          isUnauthorized: false
        } as UserState)),
        tap(() => {
          this.userStatsService.getUserContext();
          this.userStatsService.retrieveUserBalance();
          this.userStatsService.retrieveTierData();
        })
      ).subscribe(
        value => resolve(),
        error => resolve()
      );
    });
  }

}
