/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RewardAccountStatus = 'Unregistered' | 'WaitingForApproval' | 'Enabled' | 'Disabled' | 'Declined';

export const RewardAccountStatus = {
    Unregistered: 'Unregistered' as RewardAccountStatus,
    WaitingForApproval: 'WaitingForApproval' as RewardAccountStatus,
    Enabled: 'Enabled' as RewardAccountStatus,
    Disabled: 'Disabled' as RewardAccountStatus,
    Declined: 'Declined' as RewardAccountStatus
};

