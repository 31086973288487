<div *transloco="let t;">
  <span *ngIf="true">
    <div class="header__account">
      <p class="text--bold pt-4">{{ t('header.userInfoLogin.myAccountFlyOutHeadline') }}</p>
      <div class="cc_body header__popUpContent">
        <ng-container *ngIf="loginState === 'LOGOUT'; else loginUserInfoTemplate">
          <p class="px-2">{{ t('header.userInfoLogin.myAccountFlyOutSub') }}</p>
          <ul class="pl-4 header__benefitsList">
            <li>{{ t('header.userInfoLogin.myAccountFlyOutPoint1') }}</li>
            <li>{{ t('header.userInfoLogin.myAccountFlyOutPoint2') }}</li>
            <li>{{ t('header.userInfoLogin.myAccountFlyOutPoint3') }}</li>
          </ul>
        </ng-container>
        <ng-template #loginUserInfoTemplate>
          <div class="text--bold mt-2"
               (click)="openEffectiveAccountSwitcher()"
               [ngClass]="{'effectiveAccountSwitch': relatedAccounts > 1}">
            {{t('shared.welcome')}} {{userState?.userInfo?.accountName}}
          </div>
          <div *ngIf="userState?.rewardAccount?.status === 'Enabled'" class="mt-3 mb-3">
            <img src="../../../../assets/images/Certificate.svg" style="top:-1px;">
            {{(userBalance$ | async)?.pointBalance}} {{t('shared.rewardPoints')}}
          </div>
          <div *ngIf="userState?.userInfo?.isMainContact && (userState?.userInfo?.status === 'Enabled')"
               class="text--highlight mt-1">
            <img (click)="openUserRoleSettings()"
                 src="../../../../assets/images/Gear_Wheel.svg"
                 style="cursor: pointer;top:-1px;">
            <span (click)="openUserRoleSettings()" style="cursor: pointer;">
              {{t('shared.userMgmt')}}
            </span>
          </div>
        </ng-template>
        <a class="header__logInBtn btn btn-lg d-block mt-3"
           (click)="onLoginClick(loginState)">
          <ng-container *ngIf="loginState === 'LOGOUT'; else logoutButtonTemplate">
            {{ t('shared.loginButtonLabel') }}
          </ng-container>
          <ng-template #logoutButtonTemplate>
            {{ t('shared.logoutButtonLabel') }}
          </ng-template>
        </a>
        <ng-container *ngIf="loginState === 'LOGOUT'">
          <div class="header__popUp__bottom header__popUp__bottom--twolines">
            <p class="text--highlight--bold" style="margin-bottom: 35px;top:15px;">
              {{ t('header.userInfoLogin.myAccountFlyOutNoAccount') }}
            </p>
            <a (click)="openRedirectModal()"
               class="header__popUp__link text--hightlight--bold"
               style="bottom: 15px;">
              {{ t('header.userInfoLogin.myAccountFlyOutRegister') }}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </span>
</div>
