<header id="myHeader" *transloco="let t;">
  <!-- Desktop Navigation -->
  <div class="container-fluid">
    <div class="row pt-0 pt-sm-0">
      <div class="col-6 py-auto my-auto">
        <a [routerLink]="getHeaderRouter()" id="logoUrl" class="header__homeImg" queryParamsHandling="preserve">
          <img src="../../../../../assets/images/logo.png">
        </a>
      </div>
      <div class="col-6 header__loginSection no-gutters d-none d-lg-flex">
        <div class="goToAccount-flyout" (mouseover)="menuService.hoverAccount=true"
          (mouseleave)="menuService.hoverAccount=false">
          <a class="d-inline-block text-decoration-none">
            <i class="icon-man_portrait mx-1 mx-lg-3" aria-hidden="true"></i>
          </a>
          <div *ngIf="menuService.hoverAccount" class="header__popUp">
            <neo-reward-engine-web-user-info-login [userState]="userState$ | async" (logout$)="logOut()" (login$)="logIn()">
            </neo-reward-engine-web-user-info-login>
          </div>
        </div>
        <!-- Mini Cart -->
        <div *ngIf = "loggedIn && canRedeem"
            class="hdmMiniCartView "
            id="cartHeader"
            (mouseover)="menuService.hoverShoppingCart=true"
            (mouseleave)="menuService.hoverShoppingCart=false">
          <a class="cartButton"
          (click) = "closeMiniCartAndNavigateCart()"
            [ngClass]="{'empty' : cartService.redeemCartItems.length === 0 || !loggedIn || !isRegistered}">
            <i class="icon-shopping_cart"></i>
            <ng-container *ngIf="cartService.redeemCartItems.length !== 0 && loggedIn && isRegistered && canRedeem">
              {{cartService.cartItemsCount}}
              {{t('mobileMenu.miniCartItem')}}</ng-container>
          </a>
          <div *ngIf="menuService.hoverShoppingCart" class="header__popUp header__miniCart" id="hdmMiniCartView">
            <neo-reward-engine-web-mini-cart [isLoggedIn]="loggedIn"
              (closeDialogEvent)="closeMiniCartAndNavigateCart()"></neo-reward-engine-web-mini-cart>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div id="myNavbar" class="mini-dashboard" *transloco="let t;"
  [ngClass]="{'invisible': router.url.includes('checkout'), 'd-none':  router.url.includes('checkout')}">
  <div *ngIf="this.loggedIn && this.status === 'Enabled'" id="myNavDashboard"
    class="row mx-auto container-fluid xxs__padding">
    <div class="col px-0 pt-5 pb-0 d-none d-lg-block">
      <neo-reward-engine-web-mini-dashboard-textbox></neo-reward-engine-web-mini-dashboard-textbox>
    </div>
    <div class="col float-right my-auto pt-5">
      <div class="row justify-content-around">
        <div class="col-5 col-lg-7 col-xl-8 my-auto">
          <neo-reward-engine-web-progress-info-column>
          </neo-reward-engine-web-progress-info-column>
        </div>
      </div>
    </div>
  </div>
   <div *ngIf="!this.loggedIn || this.status !== 'Enabled'" id="myNavDashboard"
    class="row mx-auto container-fluid xxs__padding">
    <div class="col-12 col-lg-6 px-0 py-5">
      <neo-reward-engine-web-mini-dashboard-textbox></neo-reward-engine-web-mini-dashboard-textbox>
    </div>
  </div>
  <div class="mx-auto container-fluid horizontal-scrollable">
    <div id="myNavPoints" class="row mini-dashboard__navCirclePadding">
      <div *transloco="let t" class="col mt-auto" [ngClass]="{'mb-2':mini && isSticky,'mb-1':!mini || !isSticky}">
        <ul class="list-unstyled d-flex mb-0">
          <li *ngFor="let n of (menuService.menuConfig$ | async)?.menuItems; let last = last;" class="mr-auto mr-md-0 mx-lg-0">
            <a routerLink="{{n.routerTarget}}" class="js-menuHover-navAnchor mini-dashboard__navPoint"
              [ngClass]="{'mr-0 mr-sm-4 mr-lg-5': !last}" routerLinkActive="mini-dashboard__navPoint__active"
              [routerLinkActiveOptions]="{ exact: false }" queryParamsHandling="preserve">
              {{t(n.label)}}
            </a>
          </li>
        </ul>
      </div>
      <div id="mySmallNavCircle" *ngIf="this.loggedIn && isSticky && this.status === 'Enabled'"
        class="d-none d-lg-block col">
        <div class="row" [ngClass]="{'mini':mini && isSticky}">
          <div class="ml-auto mr-2 my-auto mini-dashboard__navCircleText">
            <i class="icon icon-reward align-middle text-white"></i>{{(userBalance$ | async)?.pointBalance}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile Navigation Footer -->
<div class="mobileNavigation" *transloco="let t;">
  <div class="row no-gutters">
    <div style="text-align: -webkit-center;"
        [class.col-4]="loggedIn && canRedeem"
        [class.col-6]="!(loggedIn && canRedeem)">
      <div class="row">
        <i (click)="openRedirectModal()" class="icon-listen text--gray mx-auto mt-1"></i>
      </div>
      <div class="row text--gray">
        <span class="mx-auto">{{t('mobileMenu.contact')}}</span>
      </div>
    </div>
    <div style="text-align: -webkit-center;"
        [class.col-4]="loggedIn && canRedeem"
        [class.col-6]="!(loggedIn && canRedeem)">
    <div class="row">
        <i class="icon-man_portrait text--gray mx-auto mt-1"
          (click)="menuService.mobileMenuToggleMyAccount = !menuService.mobileMenuToggleMyAccount"></i>
      </div>
      <div class="row text--gray">
        <span class="mx-auto">{{t('mobileMenu.profile')}}</span>
      </div>
    </div>
    <div class="col-4" style="text-align: -webkit-center;"  *ngIf="loggedIn && canRedeem">
      <div class="row">
        <i routerLink="/cart"
          [ngClass]="{
          'text--white':cartService.redeemCartItems.length !== 0,
          'text--gray':!(cartService.redeemCartItems.length !== 0),
          'cartButtonMobile':cartService.redeemCartItems.length !== 0
          }"
          class="icon-shopping_cart mx-auto mt-1"></i>
      </div>
      <div class="row text--gray">
        <span class="mx-auto">{{t('mobileMenu.cart')}}</span>
      </div>
    </div>
  </div>
  <div class="mobileNavigation__flyout mobileNavigation__myAccountFlyout text--blue"
    [ngStyle]="{'left': menuService.mobileMenuToggleMyAccount === true ? '0%' : '100%'}"
    [style.height.px]="menuService.flyoutHeight">
    <i class="icon-close mobileNavigation__flyoutClose" role="button"
      (click)="menuService.mobileMenuToggleMyAccount = !menuService.mobileMenuToggleMyAccount"></i>
    <div class="mobileNavigation__flyout__wrapper">
      <div class="position-relative h-100 p-4">
        <neo-reward-engine-web-mobile-navbar-footer [userState]="userState$ | async"
          (logout$)="logOut()" (login$)="logIn()" (closeFired$)="onAccountSwitcherClose()">
        </neo-reward-engine-web-mobile-navbar-footer>
      </div>
    </div>
  </div>
</div>
