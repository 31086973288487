import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SHARED_COMPONENTS } from './component-decl';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

//add specific bootstrap imports later on

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoRootModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorTailorModule.forRoot({
      errors: {
        useFactory(service: TranslocoService) {
          return {
            required: (error) => service.translate('error.required'),
            minlength: ({ requiredLength, actualLength }) =>
              `Expect ${requiredLength} but got ${actualLength}.`,
            email: (error) => service.translate('error.email'),
            pattern: (error) => service.translate('error.email'),
          };
        },
        deps: [TranslocoService],
      },
    }),
    NgSelectModule,
  ],
  declarations: [...SHARED_COMPONENTS],
  exports: [
    CommonModule,
    RouterModule,
    TranslocoRootModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorTailorModule,
    NgSelectModule,
    ...SHARED_COMPONENTS,
  ],
})
export class SharedModule {}
