/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Address { 
    accuracy?: string | null;
    city?: string | null;
    country?: string | null;
    countryCode?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    postalCode?: string | null;
    state?: string | null;
    stateCode?: string | null;
    street?: string | null;
}

