import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthConfiguration } from './service/auth-config';
import { ApiModule } from '@neo-reward-engine-web/ecom-api';

export const AUTH_CONFIG_TOKEN = new InjectionToken<AuthConfiguration>(
  'forRoot() Auth Configuration'
);

@NgModule({
  imports: [CommonModule, HttpClientModule, ApiModule],
})
export class AuthModule {
  static forRoot(config: AuthConfiguration): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
