import { MultiCurrencyService } from './../../../services/multi-currency/multi-currency.service';
import { Component, Input } from '@angular/core';
import { RedemptionCartDataService } from '../../../services/redemption-cart/redemption-cart-data.service';
import { CartItemDto, RewardProductDto } from '@neo-reward-engine-web/ecom-api';
import { UserStatsService } from '../../../services/user-stats/user-stats.service';

/**
 * Cart Item Row Display Component
 * (+ add, remove functionality)
 *
 * also used in checkout display on order details
 * (with disabled edit)
 */
@Component({
  selector: 'neo-reward-engine-web-redemption-cart-items',
  templateUrl: './redemption-cart-items.component.html',
  styleUrls: ['./redemption-cart-items.component.scss'],
})
export class RedemptionCartItemsComponent {
  private _rewardProduct!: CartItemDto;

  /** disables edit of quantity, true in checkout order details false in cart */
  @Input()
  public disableEdit?: boolean;
  /** wether or not copayment is active */
  @Input()
  public showCoPayment?: boolean;
  /** currency code to show in table if copayment is active */
  @Input()
  public currencyCode?: string;
  /** currency locale to show in table if copayment is active */
  @Input()
  public currencyLocale?: string;

  public newQuantity!: number;

  /**
   *
   * @param rewardCartService injected to edit quantity of products in cart
   */
  constructor(
    public rewardCartService: RedemptionCartDataService,
    private multiCurrencyService: MultiCurrencyService,
    private userService: UserStatsService
  ) {
    //
  }

  get rewardProduct(): CartItemDto {
    return this._rewardProduct;
  }
  /** input parameter of the product information */
  @Input()
  set rewardProduct(value: CartItemDto) {
    this._rewardProduct = value;
    this.newQuantity = this._rewardProduct.quantity;

  }
  /**
   * calculate quantity to send to service,
   * because cart service takes addition and removal in same parameter
   */
  get calculateQuantityParamater(): number {
    return (this._rewardProduct.quantity - this.newQuantity) * -1;
  }

  get userContext$() {
    return this.userService.userContext$;
  }

  /**
   * send new quantity to cart service
   */
  public sendCartItemChange(event : Event) {
    this.newQuantity = Number((event.target as HTMLInputElement).value);
    if (this.calculateQuantityParamater != 0) {
      this.rewardCartService.rewardProductToCart(
        this.rewardProduct.product,
        this.calculateQuantityParamater
      );
    }
  }

  public nonCashBenefitOfProductItem(
    productItem: RewardProductDto | undefined
  ): number {
    if (!productItem) {
      return 0;
    }
    return (
      productItem.nonCashBenefit.find(
        (x) => x.currency === this.multiCurrencyService.currencyCode
      )?.value || 0
    );
  }

  public localeNameOfProductItem(
    productItem: RewardProductDto,
    locale: string
  ): string {
    return productItem.name.find((x) => x.context === locale)?.value || '';
  }
}
