<div *transloco="let t" class="text--blue pt-3 pb-3">

  <div class="col-12  px-0">
    <div class=" d-inline-block">
      <div *ngIf="redirectType === 'CONTACT';else register" class="h1 h1--bold mb-4 mb-md-0 pr-0 d-block">
        {{t('header.userInfoLogin.eShopRedirect.contactFormHeadline')}}
      </div>
      <ng-template #register>
        <div class="h1 h1--bold mb-4 mb-md-0 pr-0 d-block">
          {{t('header.userInfoLogin.eShopRedirect.registerHeadline')}}
        </div>
      </ng-template>
    </div>
    <i (click)="close()" style="cursor: pointer;float:right;" class="icon icon-close"></i>
  </div>
  <div class="mt-2">
    {{t('header.userInfoLogin.eShopRedirect.salesOrganization')}}
  </div>
  <ng-select #salesOrgSelect class="custom-ng-select mb-3"
             groupBy="string"
             [(ngModel)]="eShop"
             [multiple]="false">
    <ng-option *ngFor="let shop of eShops" [value]="shop">
      <div class="row">
        <div class="col-6">
          <b>{{shop.countryWithLanguage}}</b>
        </div>
        <div class="col-6 text-right">
          <img style="height: 20px;width: 25px" src="{{shop.flagImageUrl}}">
        </div>
      </div>
    </ng-option>
  </ng-select>


  <button class="btn btn-primary" [disabled]="!salesOrgSelect.hasValue" (click)="onSubmit()" *ngIf="redirectType === 'CONTACT'">  {{t('header.userInfoLogin.eShopRedirect.contactFormBtn')}}</button>
  <button class="btn btn-primary" [disabled]="!salesOrgSelect.hasValue" (click)="onSubmit()" *ngIf="redirectType === 'REGISTER'">  {{t('header.userInfoLogin.eShopRedirect.registerBtn')}}</button>
</div>
