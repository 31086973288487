/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SocialMediaLinks } from './socialMediaLinks';


export interface SsuInformation { 
    displayCurrencyForAlternativeBillingCurrencies?: string | null;
    currencies: Array<string> | null;
    defaultLocale?: string | null;
    locales: Array<string> | null;
    socialMediaLinks?: SocialMediaLinks;
}

