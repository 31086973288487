import {createIdmRedirectUrl4Admin, redirectToCas} from './auth-utils';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError,} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  AuthConfigService, DISABLE_REDIRECT,
} from '@neo-reward-engine-web/auth';
import { UserService } from '@neo-reward-engine-web/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthAdminInterceptorService {
  constructor(
    private configuration: AuthConfigService,
    private userService: UserService,
    private router: Router,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.userService.token$.value;

    // TODO: urlToExlude
    if (accessToken) {
      const header = 'Bearer ' + accessToken;
      req = req.clone({
        headers: new HttpHeaders({ Authorization: header }),
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpResponse<any>) => {
        if (!req.context.get(DISABLE_REDIRECT) && (error.status === 401 || error.status === 403)) {
          const casURL = createIdmRedirectUrl4Admin(
            this.configuration.config.casBaseUrl,
            this.configuration.config.casClientId,
            this.configuration.config.redirectUri,
            this.configuration.config?.locale ?? 'en',
            this.configuration.config.idmAdminRedirectUri
          );
          redirectToCas(casURL);
        }
        return throwError(() => error);
      })
    );
  }
}
