import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@neo-reward-engine-web/auth';
import { ToastService } from '../../../../services/toast/toast.service';
import {ProgramSettingsService} from "../../../../services/program-settings/program-settings.service";
/**
 * Display Component that is shown if the user account isn't registered yet
 * same place as points but now with button call to action to register instead of points
 */
@Component({
  selector: 'neo-reward-engine-web-unregistered-points',
  templateUrl: './unregistered-points.component.html',
  styleUrls: ['./unregistered-points.component.scss'],
})
//TODO check if we could remove this component - it has been removed since we do not want to show the CTA in the header anymore
export class UnregisteredPointsComponent {

  private  toastParameter = {
    toastConfig: { autoClose: false, dismissible: true, duration: 5000 },
    placeholder: undefined,
  };
  /**
   *
   * @param userService injected to get the state of the user account (e.g. registration status)
   * @param router injected to route from header to registration formula
   * @param toastService injected to notify users that aren't allowed to register the account (e.g. no main contact)
   * @param programSettings injected to check if there is an active reward program
   */
  constructor(
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private programSettings: ProgramSettingsService
  ) {}

  /**
   *
   * @returns the transloco key for the button in the header (e.g. translates to Unregistered if user account is unregistered)
   */
  getRegisterButtonStatusTKey(): string {
    return (
      'register.registerBtn.' +
      (this.userService.userState$.value?.status || 'Unregistered')
    );
  }
  /**
   * called from template button to register the unregistered account from header
   */
  navigateToRegister() {
    if(!this.programIsActive && this.hasProgram){
      this.toastService.showInfoMessage('register.registration.noProgram', this.toastParameter);
      return;
    }
    if (this.userService.userState$.value?.isMainContact) {
      this.router.navigate(['register'], { queryParamsHandling: 'preserve' });
    } else if (this.userService.userState$.value?.isMainContact === false) {
      this.toastService.showInfoMessage('register.noMainContact');
    } else {
      window.location.href = window.location.protocol + "//" + window.location.host + '/register';
    }
  }

  get programIsActive(): boolean {
    return this.programSettings.programSettings$.value?.enabled || false;
  }

  get hasProgram(): boolean {
    return !(this.programSettings.programSettings$.value === undefined);
  }
}
