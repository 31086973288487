import { MenuService } from './../menu/menu.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { RewardTransactionViewModel } from '../reward/reward-transaction-view.model';
import { convertRewardTransactionToClientModel } from '../reward/reward-data.helper';
import {
  getLastDayOfMonth,
  getFirstDayOfMonth,
} from './user-stats.util';
import { loggerFn } from '../common-helper/pipe.helper';
import { NGXLogger } from 'ngx-logger';
import { StandardErrorHandlerService } from '../error-handler/standard-error-handler.service';
import {
  CommunityUserService,
  RewardAccountDto,
  RewardOrderDto,
  RewardTierDto,
  UserBalance,
  UserContext,
} from '@neo-reward-engine-web/ecom-api';
import { EnvironmentService } from '../environment/environment.service';
import { ProgramSettingsService } from '../program-settings/program-settings.service';

/**
 * service that contains all stats regarding the current effective account of the user
 * (e.g. transaction history, tier data, expiring points etc.)
 */
@Injectable({
  providedIn: 'root',
})
export class UserStatsService implements OnDestroy{
  private _userBalance$ = new BehaviorSubject<UserBalance | null>(null);
  private _totalRedemptions$ = new BehaviorSubject<number>(0);
  private _totalPointEarnings$ = new BehaviorSubject<number>(0);
  private _rewardTransactions$ = new BehaviorSubject<
    RewardTransactionViewModel[]
  >([]);
  private _earningsFromTo$ = new BehaviorSubject<number[]>([]);
  private _redemptionsFromTo$ = new BehaviorSubject<number[]>([]);
  private _expiringPoints$ = new BehaviorSubject<number>(0);
  private _tierData$ = new BehaviorSubject<RewardTierDto[] | null>(null);
  private _userAccount$ = new BehaviorSubject<RewardAccountDto | null>(null);
  private _userContext$ = new BehaviorSubject<UserContext | null>(null);

  subscriptions: Subscription = new Subscription();

  constructor(
    private readonly communityUserService: CommunityUserService,
    private readonly logger: NGXLogger,
    private readonly _errorHandler: StandardErrorHandlerService,
    private readonly environmentService: EnvironmentService,
    private readonly menuService: MenuService,
    private readonly settingService: ProgramSettingsService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get userBalance$(): Observable<UserBalance | null> {
    return this._userBalance$.asObservable();
  }

  get totalRedemptions$(): Observable<number> {
    return this._totalRedemptions$.asObservable();
  }

  get totalPointEarnings$(): Observable<number> {
    return this._totalPointEarnings$.asObservable();
  }

  get rewardTransactions$(): Observable<RewardTransactionViewModel[]> {
    return this._rewardTransactions$.asObservable();
  }

  get earningsFromTo$(): Observable<number[]> {
    return this._earningsFromTo$.asObservable();
  }

  get redemptionsFromTo$(): Observable<number[]> {
    return this._redemptionsFromTo$.asObservable();
  }

  get expiringPoints$(): Observable<number> {
    return this._expiringPoints$.asObservable();
  }

  get tierData$(): Observable<RewardTierDto[] | null> {
    return this._tierData$.asObservable();
  }

  get currentTier$(): Observable<RewardTierDto | null | undefined> {
    return this._tierData$.pipe(
      map((tiers) => tiers?.find((tier) => tier.isCurrent === true))
    );
  }

  get nextTier$(): Observable<RewardTierDto | null | undefined> {
    const nextTier = new Subject<RewardTierDto>();
    this.subscriptions.add(this.currentTier$.pipe(take(1)).subscribe((tier) => {
      if (tier) {
        this.getTierByLevel$(tier.level + 1)
          .pipe(take(1))
          .subscribe((tierByLevel) => {
            if (tierByLevel) {
              nextTier.next(tierByLevel);
            }
          });
      }
    }));
    return nextTier.asObservable();
  }

  get userAccount$(): Observable<RewardAccountDto | null> {
    return this._userAccount$.asObservable();
  }

  get userContext$(): Observable<UserContext | null> {
    return this._userContext$.asObservable();
  }

  /** refresh user context available in userContext$ */
  getUserContext() {
    if (this.menuService.isLoggedIn) {
      this.subscriptions.add(this.communityUserService
        .userContext()
        .subscribe({
          next: (userContext) => {
            this._userContext$.next(userContext);
            this.logger.log('UserStatsService.getUserContext successfull');
          },
          error: (e) => {
            this._errorHandler.handleError(
              e,
              'UserStatsService.getUserContext'
            );
            this.logger.log(
              'Error retrieving UserContext UserStatsService.getUserContext , ',
              e
            );
          },
        }));
    }
  }
  /** refresh user account info available in userAccount$ */
  retrieveUserAccount(): void {
    this.logger.log('UserStatsService.retrieveUserAccount triggered');
    if (this.menuService.isLoggedIn) {
      this.subscriptions.add(this.communityUserService
        .getUserRewardAccount()
        .subscribe({
          next: (rewardAccountDto) => {
            this._userAccount$.next(rewardAccountDto);
            this.logger.log('UserStatsService.retrieveUserAccount successfull');
          },
          error: (error) => {
            this._errorHandler.handleError(
              error,
              'FooterService.retrieveSsuInfo'
            );
            this.logger.log('UserStatsService.retrieveUserAccount failed');
          }
      }));
    }
  }
  /** refresh user balance info available in userBalance$ */
  retrieveUserBalance(): void {
    if (this.menuService.isLoggedIn) {
      this.subscriptions.add(this.communityUserService
        .getUserBalance()
        .pipe(loggerFn(this.logger, 'UserStatsService.retrieveUserBalance'))
        .subscribe((balance) => this._userBalance$.next(balance)));
    }
  }

  /**
   * refresh total transactions for account available in rewardTransactions$
   * TODO: ZoneOffset of Account
   */
  retrieveTotalTransactions(): void {
    if (this.menuService.isLoggedIn) {
      let totalRedemptions = 0;
      let expiringPoints = 0;

      this.subscriptions.add(this.communityUserService
        .getUserBalanceTransactions()
        .pipe(
          loggerFn(this.logger, 'UserStatsService.retrieveTotalTransactions'),
          tap((transactions) =>
            transactions.transactions.forEach((transaction) => {
              if (transaction.type == 'Redemption') {
                totalRedemptions += 1;
              }
              const today = new Date();
              const futureDate = new Date(today.setDate(today.getDate() + ((this.settingService.programSettings$.value?.pointExpiry.reminderPeriodWeeks || 12) * 7)))
              if (
                transaction.type == 'Reward' &&
                new Date(transaction.expiringDate) >= today &&
                new Date(transaction.expiringDate) <= futureDate
              ) {
                expiringPoints += transaction.points - transaction.usedPoints;
              }
            })
          ),
          tap((transactions) =>
            this._totalPointEarnings$.next(transactions.earned)
          ),
          map((transactions) =>
            transactions.transactions.map((transaction) =>
              convertRewardTransactionToClientModel(transaction, 'UTC+2')
            )
          )
        )
        .subscribe((transactions) => {
          this._totalRedemptions$.next(totalRedemptions);
          this._rewardTransactions$.next(transactions);
          this._expiringPoints$.next(expiringPoints);
        }));
    }
  }

  /**
   * overrides date range filtered data
   * retrieves last 12 months, available in redemptionsFromTo$ and earningsFromTo$
   */
  retrieveTotalTransactionPointsForLast12Months() {
    if (this.menuService.isLoggedIn) {
      const redemptionData: number[] = Array(12).fill(0);
      const earningData: number[] = Array(12).fill(0);

      this.subscriptions.add(this.communityUserService
        .getYearUserBalanceTransactions()
        .pipe(
          loggerFn(
            this.logger,
            'UserStatsService.retrieveTotalTransactionPointsForLast12Months'
          ),
          tap((transactions) => {
            transactions.reverse();
            transactions.forEach((transaction) => {
              redemptionData[transactions.indexOf(transaction)] =
                transaction.redeemed;
              earningData[transactions.indexOf(transaction)] =
                transaction.earned;
            });
          })
        )
        .subscribe(() => {
          this._earningsFromTo$.next(earningData);
          this._redemptionsFromTo$.next(redemptionData);
        }));
    }
  }

  /**
   * overrides last 12 months data
   * @param dateRange range of dates to retrieve transaction points for, available in redemptionsFromTo$ and earningsFromTo$
   */
  retrieveTransactionPointsDateRange(dateRange: Date[]): void {
    if (this.menuService.isLoggedIn) {
      const redemptionData: number[] = Array(dateRange.length).fill(0);
      const earningData: number[] = Array(dateRange.length).fill(0);
      const dateRangeIndexes = dateRange.map((date) => date.getMonth());

      this.subscriptions.add(this.communityUserService
        .getUserBalanceTransactions(
          getFirstDayOfMonth(dateRange[0]),
          getLastDayOfMonth(dateRange.slice(-1)[0])
        )
        .pipe(
          loggerFn(
            this.logger,
            'UserStatsService.retrieveTransactionPointsDateRange'
          ),
          map((transactions) => transactions.transactions),
          tap((transactions) =>
            transactions.forEach((transaction) => {
              switch (transaction.type) {
                case 'Redemption': {
                  redemptionData[
                    dateRangeIndexes.indexOf(
                      new Date(transaction.timestamp).getMonth()
                    )
                  ] += transaction.points;
                  break;
                }
                case 'Reward': {
                  earningData[
                    dateRangeIndexes.indexOf(
                      new Date(transaction.timestamp).getMonth()
                    )
                  ] += transaction.points;
                  break;
                }
              }
            })
          )
        )
        .subscribe(() => {
          this._earningsFromTo$.next(earningData);
          this._redemptionsFromTo$.next(redemptionData);
        }));
    }
  }

  /**
   * refresh tier data available in tierData$
   */
  retrieveTierData() {
    if (this.menuService.isLoggedIn) {
      this.subscriptions.add(this.communityUserService
        .getUserRewardTiers()
        .subscribe((tierData) => this._tierData$.next(tierData)));
    }
  }
  /**
   * @param level level to return
   * @return observable of tier that corresponds with the supplied level
   */
  getTierByLevel$(level: number): Observable<RewardTierDto | null | undefined> {
    return this._tierData$.pipe(
      map((tiers) => tiers?.find((tier) => tier.level === level))
    );
  }
  /**
   *
   * @param rewardOrderId order id to return order from
   * @returns observable of reward order that corresponds with the supplied id
   */
  retrieveUserOrder(rewardOrderId: string): Observable<RewardOrderDto> {
    return this.communityUserService.getUserOrder(rewardOrderId);
  }
}
