/** interface to work easier with the toast options of hottoast lib  */
export interface ToastConfigOptions {
  /** wether or not the toast should autoclose */
  autoClose: boolean;
  /** wether or not the toast is user dismissible */
  dismissible: boolean;
  /** how long the toast should stay on screen */
  duration?: number;
}

/** interface to work easier with the toast paramerters of hottoast lib */
export interface ToastParameter {
  /** toastconfig parameter in form of our own interface */
  toastConfig: ToastConfigOptions;
  /** placeholder transloco key */
  placeholder: { [key: string]: string } | undefined;
}

/** default parameter configuration for our hottoast wraper service */
export const defaultToastParameter = {
  toastConfig: { autoClose: true, dismissible: false, duration: 5000 },
  placeholder: undefined,
};
