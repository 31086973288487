/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PointExpiry } from './pointExpiry';
import { PointRoundingRule } from './pointRoundingRule';
import { RewardProgramDocument } from './rewardProgramDocument';
import { TierSystem } from './tierSystem';
import { PointValuation } from './pointValuation';


export interface RewardProgram { 
    id?: string | null;
    name: string;
    ssu: string;
    contact?: string | null;
    fulfillment?: string | null;
    termsAndConditions?: string | null;
    longDescription?: string | null;
    shortDescription?: string | null;
    showNonCashBenefit?: boolean;
    bannerUrl?: string | null;
    enabled: boolean;
    currencyName: string;
    currencyAbbreviation: string;
    copayment: boolean;
    joinRequests: boolean;
    pointExpiry: PointExpiry;
    pointValuation: PointValuation;
    productDetailPage: boolean;
    pointRoundingRule: PointRoundingRule;
    orderDetails: boolean;
    tierSystem: TierSystem;
    documents?: Array<RewardProgramDocument> | null;
    autoRegisterAllAccounts?: boolean;
}

