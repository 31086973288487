import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MenuService } from '../menu/menu.service';
import { MultilanguageService } from '../multilanguage/multilanguage.service';

/**
 * only resolves registered and authenticated users
 * -> sets the menu items for COMMUNITY
 */
@Injectable({
  providedIn: 'root',
})
export class CommunityResolver implements Resolve<boolean> {
  constructor(
    private readonly menuService: MenuService,
    private multiLanguageService: MultilanguageService
  ) {}

  resolve(): Observable<boolean> {
    this.multiLanguageService.setLanguage();
    this.menuService.setMenuItems('COMMUNITY');
    return of(true);
  }
}
