// CAS Oidc CodeFlow:
// Request Access Parameters:
//

import {DateTime} from "luxon";
import {UserState} from "./user.state";

export type ExternalErrorCode = '';

export const parseUrlParameter = (href: string): URLSearchParams => {
  const url = new URL(href);
  return url.searchParams;
};

export const decodeTokenWithoutValidating = (token: string): any => {
  const base64Url = token.split('.');

  if (!(base64Url.length >= 2)) {
    return null;
  }

  const base64 = decodeURIComponent(
    atob(base64Url[1])
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(base64);
};

/**
 * Check for expired Tokens.
 *
 * @param exp Expiration in Seconds since 01.01.1970
 * @param offset Offset defaults to 1800 sec - contingency working time
 */
export const hasTokenExpired = (
  exp: number,
  offset: number = 1800
): boolean => {
  const now = Date.now();
  return exp <= now / 1000 + offset;
};

export const createCasUrl = (
  baseUrl: string,
  clientId: string,
  redirectUri: string,
  locale: string
) =>
  encodeURI(
    // tslint:disable-next-line:max-line-length
    `${baseUrl}/cas/oidc/authorize?response_type=code&scope=openid+profile+heidelberg_id&client_id=${clientId}&redirect_uri=${redirectUri}&locale=${locale}`
  );

export const createIdmRedirectUrl = (
  baseUrl: string,
  clientId: string,
  redirectUri: string,
  locale: string,
  idmRedirectUri: string
) =>
  encodeURI(
  `${baseUrl}${idmRedirectUri}&scope=openid+profile+heidelberg_id&client_id=${clientId}&redirect_uri=${redirectUri}&locale=${locale}`
);

export const createIdmRedirectUrl4Admin = (
  baseUrl: string,
  clientId: string,
  redirectUri: string,
  locale: string,
  idmRedirectUri: string
) =>
  encodeURI(
  `${baseUrl}${idmRedirectUri}&scope=openid+profile+heidelberg_id&client_id=${clientId}&redirect_uri=${redirectUri}&locale=${locale}`
);
export const redirectToCas = (casUrl: string) =>
  (window.location.href = casUrl);

export const saveToken = (key: string, token: string) =>
  sessionStorage.setItem(key, token);

export const getToken = (key: string): string | null =>
  sessionStorage.getItem(key);

export const redirectToExternalErrorPage = (
  url: string,
  reason: ExternalErrorCode
) => (window.location.href = `${url}?reason=${reason}`);

export const containsUrlToExclude = (url: string, casUrlFragment: string) =>
  url.includes(casUrlFragment);

export const isRedirectToCasRequired = (
  publicUrls: string[],
  href: URL
): boolean =>
  publicUrls.map((url) => href.pathname === url).every((vote) => !vote);

export const checkIfTokenExpired = (
  expiresAtAsIso: string,
  compareDateTime: DateTime
): boolean => {
  const diffMinutes = DateTime.fromISO(expiresAtAsIso).diff(compareDateTime, 'minutes').minutes;
  return diffMinutes < 15;
}

export const mapBackendUserState = (userState: Partial<UserState>): UserState => {
  const state = Object.assign({} as UserState, userState);
  state.isUnauthorized = false;
  state.isAuthenticated = true;
  return state;
}
