import { Injectable } from '@angular/core';
import { ErrorHandler } from './error-handler';
import { NGXLogger } from 'ngx-logger';
import { ToastService } from '../toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * service that handles errors by showing localized error messages and logging to console
 */
@Injectable({
  providedIn: 'root',
})
export class StandardErrorHandlerService implements ErrorHandler {
  constructor(
    private readonly logger: NGXLogger,
    private readonly toastService: ToastService
  ) {}

  handleError(response: any, source: string): void {
    if (response instanceof HttpErrorResponse) {
      if (response.error instanceof ErrorEvent) {
        // Client error Event has occured
        this.logger.error(
          `Client Error Event occured at: ${source} -> Error Details:`,
          response
        );
        this.toastService.showErrorMessage('shared.technicalError');
      } else {
        // Server Side Error Code received
        this.logger.error(
          `Server Side Error Event occured at: ${source} -> Error Details:`,
          response
        );
        this.toastService.showErrorMessage('shared.technicalError');
      }
    } else {
      // Unknown type of Error
      this.logger.fatal(
        `Unknown type of Error occured at: ${source} -> Error Details:`,
        response
      );
      this.toastService.showErrorMessage('shared.technicalError');
    }
  }
}
