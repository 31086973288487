<div *transloco = "let t">
    <div class = "col-12  px-0 pt-3 pt-md-0">
        <div class =" d-inline-block">
            <div class="modal-header h1 h1--bold mb-2 mb-md-0 pr-0 d-block d-md-none text--blue" *ngIf="userContext$ | async; let userContext;">
                {{localeNameOfProductItem(productItem, userContext.userInfo!.language!)}}
            </div>
        </div>
        <i (click)="close()" style="cursor: pointer;float:right;position:absolute;right:0px;"
            class="icon icon-close"></i>
    </div>

    <div  class ="row w-100 text--blue">
        <div class = "col-12 col-md-1 order-2 order-md-1 text-center p-0" *ngIf = "productItem.productImages!.length > 1">
            <!-- point slider-->
            <div class="d-block d-md-none ">
                <ul style="display: inline-flex; padding: 0px;">
                    <li class="nav-dots" *ngFor="let image of productItem.productImages, index as i">
                        <label class="nav-dot" [ngClass]="{'current-img': i === imgIndividualIndex}"></label>
                    </li>
                </ul>
            </div>
            <!-- point slider-->

            <!-- picture slider-->
            <i class = "icon-collapse_up d-none d-md-block"
                [ngClass] ="{'inactive' : imgSliderIndex === 0}"
                (click) = "productsPrevious()"
                style = "cursor: pointer;"
               *ngIf = "productItem.productImages!.length > 3"></i>
                <ng-container  *ngFor = "let image of productItem.productImages!, index as i">
                    <div class="d-none d-md-inline-flex hoverImg" [ngClass] = "{'notChosenImg': imgIndividualIndex !== i}"
                     style = "height: 50px;width: 50px"
                     *ngIf="i <= (numberOfImagesToShow + imgSliderIndex) && i >= (0 + imgSliderIndex)" >
                        <img class ="mb-3"
                        style = "cursor: pointer; max-height: 50px; max-width: 50px;margin: auto !important"
                        src= "{{productItem.productImages![i] || '/assets/images/no-image.jpg'}}"
                        (click) = "selectImg(i)">
                    </div>
                </ng-container>
            <!-- picture slider-->
            <i
            [ngClass] ="{'inactive' : productItem.productImages!.length - 2 < this.imgSliderIndex + this.numberOfImagesToShow}"
            class = "icon-collapse_down d-none d-md-block"
            *ngIf = "productItem.productImages!.length > 3"
            (click) = "productsNext(productItem.productImages!.length)"
            style = "cursor: pointer;"></i>
        </div>

        <div class ="col-12 order-1 order-md-2 text-center d-flex align-items-center" [ngClass] ="{'col-md-5':productItem.productImages!.length > 1,'col-md-6':productItem.productImages!.length === 1 }">
            <i class = "icon-collapse_left  d-md-none" *ngIf = "productItem.productImages!.length > 1" (click) = "swipeLeft()" style = "position: relative;top: -8px; cursor: pointer;"></i>
                <div class = "detailPagePrimaryImgContainer mx-auto d-lg-inline mb-md-5">
                <img class="detailPagePrimaryImg"

                    [ngClass] = "{'inactive': !productItem.active}"
                    src = "{{productItem.productImages![imgIndividualIndex] || '/assets/images/no-image.jpg'}}">
                </div>


            <i class="icon-collapse_right  d-md-none" *ngIf="productItem.productImages!.length > 1"
                (click)="swipeRight()" style="position: relative;top: -8px; cursor: pointer;"></i>
        </div>

        <div class="col-12 order-3" *ngIf="userContext$ | async; let userContext;">
            <div class="modal-header h1 h1--bold mb-2 mb-md-0 pr-0 d-none d-md-block" >
                {{localeNameOfProductItem(productItem, userContext.userInfo!.language!)}}
            </div>
            <div class="modal-body ">
                <div class="row">
                    <div class="col-12 order-2 order-md-1 my-2">
                        {{localeDescriptionOfProductItem(productItem, userContext.userInfo!.language!)}}
                    </div>
                    <div class="col-12 order-1 order-md-2">
                        <b style="font-size: 24px;">{{productItem.points}} {{ t('rewards.productTile.points') }}</b>
                        <span *ngIf="hasCoPayment">&nbsp;(+{{nonCashBenefitOfProductItem(productItem)|currency: currency :'symbol':
                            '1.2-2':locale}} {{ t('rewards.productTile.copayment') }})</span>
                    </div>
                </div>
                <hr class="d-none d-md-block">
                <div class="row align-items-center">
                    <div class="col-4 col-md-5 col-lg-4">
                        {{ t('rewards.productTile.quantity') }}:
                    </div>
                    <div class="col-4 col-md-5 col-lg-4">
                        <input [(ngModel)]="quantity" type="number" class="form-control" min="1" max="2147483647" width="100%">
                    </div>
                </div>
            </div>

            <div class="col-12 align-self-end px-0">
                <button [disabled]="showAnimation"
                    class="btn btn-lg btn-primary btn-block d-flex justify-content-center align-items-center btn--var-text-icon mt-3 mt-lg-auto"
                    (click)="addToCart(productItem)">
                    <div *ngIf="!showAnimation"
                        class="align-items-center d-flex justify-content-around position-relative">
                        <i class="icon-shopping_cart"></i>
                        <span>{{ t('rewards.productTile.addToCart') }}</span>
                    </div>
                    <div *ngIf="showAnimation"
                        class="align-items-center d-flex justify-content-around position-relative">
                        <lottie-player src="../../../../assets/lotties/addToCart-lottie.json" background="transparent"
                            style="height: 60px;" speed="1" autoplay>
                        </lottie-player>
                    </div>
                </button>
            </div>
            <button class="btn btn-prim-outline w-100" [disabled] ="cartDataService.redeemCartItems.length === 0" (click)="clickOnCart()">{{t('mobileMenu.toCart')}}</button>
        </div>
    </div>
</div>
