/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Category } from './category';
import { RuleCondition } from './ruleCondition';
import { RuleAction } from './ruleAction';
import { RuleMode } from './ruleMode';
import { RuleType } from './ruleType';
import { RewardRuleTier } from './rewardRuleTier';


export interface RewardRule { 
    id?: string | null;
    active: boolean;
    ssu: string;
    name: string;
    startDate: string;
    endDate: string;
    type: RuleType;
    action: RuleAction;
    condition: RuleCondition;
    mode: RuleMode;
    tiers: Array<RewardRuleTier>;
    /**
     * If true then the rule can be added to another \'addable\' rules
     */
    isAddable?: boolean;
    categories?: Array<Category> | null;
}

