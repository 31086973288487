/**
 * gets all months between two dates in ISO Format
 *
 * @param from start date
 * @param to end date
 *
 * @returns array of all months from to ("YYYY-MM")
 *  */
export const getRangeOfMonths = (from: Date, to: Date, days?: boolean) => {
  const dates = new Array<Date>();

  /** avoids edge case where last month is skipped */
  if (!days) {
    from.setDate(1);
    to.setDate(28);
  }

  while (from <= to) {
    dates.push(new Date(from));
    from.setMonth(from.getMonth() + 1);
  }
  return dates;
};

/** HELPER FUNCTION to get last day of a given month */
export const getLastDayOfMonth = (date: Date): string => {
  const dateWithlastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1
  );
  return dateWithlastDayOfMonth.toISOString().substring(0, 10);
};

/** HELPER FUNCTION to get first day of a given month */
export const getFirstDayOfMonth = (date: Date): string => {
  const dateWithlastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  );
  return dateWithlastDayOfMonth.toISOString().substring(0, 10);
};
