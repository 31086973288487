import { MultiCurrencyService } from './modules/services/multi-currency/multi-currency.service';
import { Component, OnInit } from '@angular/core';
import { ProgramSettingsService } from './modules/services/program-settings/program-settings.service';
import {UserService} from "@neo-reward-engine-web/auth";
import {Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'neo-reward-engine-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   *
   * @param programSettingsService injected to retrieve programsettings if user is logged in
   * @param multiCurrencyService
   * @param userService
   * @param router
   * @param logger
   */
  constructor(
    private readonly programSettingsService: ProgramSettingsService,
    private readonly multiCurrencyService: MultiCurrencyService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly logger: NGXLogger,
  ) {}

  /** retrieve program settings on init */
  ngOnInit(): void {
    this.programSettingsService.retrieveAllProgramSpecificSettings();
    this.multiCurrencyService.retrieveMutliCurrencyParams();
    this.performRedirectIfRequired();
  }

  private performRedirectIfRequired(): void {
    if (this.userService.userState$.value !== null) {
      this.logger.debug('AppComponent.performRedirectIfRequired() - user is present');
      this.router.navigate(['overview']);
    }
  }
}
