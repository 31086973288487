import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { httpClientIntegration } from "@sentry/integrations";

// Switch on Sentry Crash reporting
// two environments production and stage-dev
Sentry.init({
  dsn: 'https://47ee4ddf1c7b4026abd042a3241f4a55@o276800.ingest.sentry.io/6238860',
  integrations: [httpClientIntegration({failedRequestStatusCodes: [400, 599]})],
  environment: environment.production ? 'production' : 'stage-dev',
  sendDefaultPii: true,
});
if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
