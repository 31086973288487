import { Component } from '@angular/core';
import { UserStatsService } from '../../../../services/user-stats/user-stats.service';

@Component({
  selector: 'neo-reward-engine-web-progress-info-column',
  templateUrl: './progress-info-column.component.html',
  styleUrls: ['./progress-info-column.component.scss'],
})
export class ProgressInfoColumnComponent {
  /**
   *
   * @param userStatsService injected to retrieve user account balance
   */
  constructor(public userStatsService: UserStatsService) {
    //
  }

  get userBalance$() {
    return this.userStatsService.userBalance$;
  }

  /**
   * to display the last refresh time in header dashboard
   * @param date last time of data refresh
   * @returns formatted date string of input date
   */
  getDateTime(date: string | undefined): string {
    if (date && new Date(date).getFullYear() > 2000) {
      const returnDate = new Date(date);
      return (
        returnDate.toLocaleDateString(navigator.language) +
        ' ' +
        returnDate.getHours() +
        ':' +
        returnDate.getMinutes()
      );
    } else {
      return '-';
    }
  }
}
