import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../../services/menu/menu.service';
/**
 * DEPRECATED header component
 * old call to action card
 */
@Component({
  selector: 'neo-reward-engine-web-progress-info-card',
  templateUrl: './progress-info-card.component.html',
  styleUrls: ['./progress-info-card.component.scss'],
})
export class ProgressInfoCardComponent {
  /**
   *
   * @param router injected to navigate with the
   * @param menuService injected to get current route as translation key
   */
  constructor(private router: Router, private menuService: MenuService) {
    //
  }

  /** return translation key for card content based on generic route translation key */
  get cardTextTranslationKey() {
    switch (this.menuService.getRouterTranslation('')) {
      case 'rewards': {
        return 'header.miniDashboard.infoColumn.cardTextHowTo';
      }
      default: {
        return 'header.miniDashboard.infoColumn.cardTextBrowse';
      }
    }
  }

  /** navigate by clicking on the cart depending on the current route (e.g. from anywhere except rewards to rewards, from rewards to reward tier status) */
  navigateWithCard(): void {
    if (this.cardTextTranslationKey.includes('HowTo')) {
      this.router.navigate(['reward-tier-status'], {
        queryParamsHandling: 'preserve',
      });
    } else {
      this.router.navigate(['rewards'], { queryParamsHandling: 'preserve' });
    }
  }
}
