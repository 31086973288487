import { NGXLogger } from 'ngx-logger';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

/**
 * loggerFn
 *
 * Central logger function for RxJS pipes.
 *
 * @param logger
 * @param functionName
 * @param message
 */
export const loggerFn = <T>(
  logger: NGXLogger,
  functionName: string,
  message: string = 'Data logger'
) => tap<T>((t) => logger.debug(`${message} - ${functionName}: `, t));

/**
 * handleErrorFn
 *
 * Central error handler for RxJS pipes.
 *
 * @param message
 */
export const handleErrorFn = (message: string) => {
  return (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // TODO Once we have a spinner service switch it off
    // if (stopSpinner) {
    //   await loadingService.dismiss();
    // }

    // TODO Optional thing -> show default error toast to the user
    // const toast = await toastrService
    //   .create({header: 'Error',
    //     color: 'danger',
    //     message: `An error has occurred: ${message} - ${errorMessage}!`,
    //     duration: 10000});
    // await toast.present();
    return throwError(new Error(errorMessage));
  };
};
