import { Injectable } from '@angular/core';

/**
 * central loading indicator service
 * TODO: Replace loading indicator with the nicer looking one from the eshop
 */
@Injectable({
  providedIn: 'root',
})
export class LoadIndicatorService {
  private counter = 0;
  private _isVisible = false;

  constructor() {
    //
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  public showLoadIndicator(): void {
    this.counter++;
    if (this.counter > 0 && !this._isVisible) {
      this._isVisible = true;
    }
  }

  public hideLoadIndicator(): void {
    this.counter--;
    if (this.counter === 0) {
      this._isVisible = false;
    }
  }
}
