/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RuleType = 'None' | 'EShop' | 'Service';

export const RuleType = {
    None: 'None' as RuleType,
    EShop: 'EShop' as RuleType,
    Service: 'Service' as RuleType
};

