/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ssu } from './ssu';


export interface WidgetContent { 
    id: string | null;
    headline: string | null;
    description?: string | null;
    url?: string | null;
    ssu?: Ssu;
    isActive?: boolean;
    locale?: string | null;
}

