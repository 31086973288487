import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '@neo-reward-engine-web/auth';
import { RedemptionCartDataService } from '../../../services/redemption-cart/redemption-cart-data.service';
import { RewardProductDto } from '@neo-reward-engine-web/ecom-api';
import { UserStatsService } from '../../../services/user-stats/user-stats.service';

/**
 * Mini Cart Display Component to use in flyout of header
 */
@Component({
  selector: 'neo-reward-engine-web-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss'],
})
export class MiniCartComponent {
  /**
   * gets passed the login status of the user
   */
  @Input()
  public isLoggedIn!: boolean | undefined;
  /**
   * outputs close event to navigate to cart via cart button
   */
  @Output()
  public closeDialogEvent = new EventEmitter<string>();
  /**
   *
   * @param rewardCartService injected to allow removal of items from cart via flyout
   * @param userService injected to know the user (e.g. if hes registered and enabled)
   */
  constructor(
    private userService: UserService,
    public rewardCartService: RedemptionCartDataService,
    private userStatsService: UserStatsService
  ) {
    //
  }

  /** (e.g. show cart data or alternative text?) */
  get isRegistered(): boolean {
    return this.userService.userState$.value?.status === 'Enabled';
  }
  /** show the total point value of the current cart */
  get totalPointsCart(): number {
    return this.rewardCartService.calculatePointsLocal();
  }

  /**
   * routes to cart if dialogue is explicitly closed,
   * hover out of flyout does not trigger this
   */
  clickOnCart() {
    this.closeDialogEvent.emit('close');
  }
  /**
   * removes quantity one by one until 0
   * @param product which product is removed
   */
  removeOneFromCart(product: RewardProductDto) {
    this.rewardCartService.rewardProductToCart(product, -1);
  }

  get userContext$() {
    return this.userStatsService.userContext$;
  }

  public localeNameOfProductItem(
    productItem: RewardProductDto,
    locale: string
  ): string {
    return productItem.name.find((x) => x.context === locale)?.value || '';
  }
}
