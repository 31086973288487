<div class="row mt-2 justify-content-center py-3 position-relative orderHistory__myPagination" *transloco="let t;">
    <!-- <div class="col float-left my-auto">
        <div class="product-tile__SKU">
            <span #upperLeftContentSlot>
                <ng-content select="[upperLeftContentSlot]"></ng-content>
            </span>
            <span *ngIf="!upperLeftContentSlot.hasChildNodes()">sample content upper left</span>
        </div>
    </div> -->

    <div class="col-9 col-sm-7 mb-0 pr-md-0 pr-xl-4 my-auto">
        <div class="d-flex align-items-center justify-content-end">
            <ul class="pagination pr-3 pr-md-0">
                <li class="page-item" [ngClass]="{ 'disabled': page === 1}">
                    <a class="page-link previousAction pageAction" (click)="changePage(page-1)">
                        <span aria-hidden="true"><i class="icon-collapse_left"></i></span>
                        <span class="sr-only">{{ t('rewards.pagination.previous') }}</span>
                    </a>
                </li>


            <li class="page-item " *ngIf="page>1"><a class="page-link gotoPage"
                (click)="changePage(1)">{{1}}</a></li>

            <li class="page-item disabled" *ngIf="page>2"><a class="page-link">⋯</a></li>

            <!-- <li class="page-item" *ngIf="(page - 2)>0"><a class="page-link" (click)="changePage(page-2)">{{page -
                    2}}</a></li> -->

            <!-- <li class="page-item" *ngIf="(page - 1)>0"><a class="page-link" (click)="changePage(page-1)">{{page -
                    1}}</a></li> -->

            <!-- active page (blue)-->
            <li class="page-item active"><a class="page-link">{{page}}</a></li>


            <li class="d-none d-sm-inline page-item" *ngIf="(page + 1)<pageMax || (page + 1) === pageMax "><a
                    class="page-link gotoPage" (click)="changePage(page+1)">{{page + 1}}</a></li>

            <li class="d-none d-sm-inline page-item" *ngIf="(page + 2)<pageMax || (page + 2) === pageMax "><a
                    class="page-link gotoPage" (click)="changePage(page+2)">{{page + 2}}</a></li>

            <!-- <li class="page-item disabled d-none d-sm-inline" *ngIf="(page + 3)<pageMax"><a class="page-link">⋯</a>
            </li> -->
            <li class="page-item disabled" *ngIf="pageMax>4 && (pageMax-page)>=4"><a class="page-link">⋯</a></li>
            <li class="page-item  d-sm-inline" *ngIf="(page + 2)<pageMax"><a class="page-link gotoPage"
                    (click)="changePage(pageMax)">{{pageMax}}</a></li> 



                <li class="page-item" [ngClass]="{ 'disabled': page === pageMax}">
                    <a class="page-link nextAction pageAction" (click)="changePage(page+1)">
                        <span aria-hidden="true"><i class="icon-collapse_right"></i></span>
                        <span class="sr-only">{{ t('rewards.pagination.next') }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-3 col-sm-5 ">
        <div class="row">
            <div class="d-none d-sm-block col my-auto text-right text--blue text--bold">{{ t('rewards.pagination.resultsPerPage') }}</div>
            <div class="select-form-control-wrapper my-auto float-right" style="width:max-content;">
                <select id="itemsPerPageSelector" [(ngModel)]="itemsPerPageSelection"
                    (change)="changeItemsPerPage(itemsPerPageSelection)" class="select-form-control text-large"
                    #itemsPerPage
                    style = "border-radius: 10%;">
                    <option *ngFor="let option of itemNumberOptions" [ngValue]="option">{{option}}</option>
                </select>
            </div>
        </div>
    </div>

</div>
