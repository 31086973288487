/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { RewardCartItemDto } from './rewardCartItemDto';


export interface RewardOrderDto { 
    id: string;
    orderId: string;
    accountId?: string | null;
    sapAccountNumber: string;
    cart: Array<RewardCartItemDto>;
    timestamp: string;
    nonMonetaryBenefits: number;
    shipping: number;
    total: number;
    totalItems: number;
    billingAddress: Address;
    additionalInformation?: string | null;
    orderProcessed?: boolean;
}

