import { ProgramSettingsService } from '../../../services/program-settings/program-settings.service';
import { UserContext } from '@neo-reward-engine-web/ecom-api';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  UserService,
  redirectToCas,
  AuthConfigService, createIdmRedirectUrl,
} from '@neo-reward-engine-web/auth';
import { Observable, Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenuService } from '../../../services/menu/menu.service';
import { UserStatsService } from '../../../services/user-stats/user-stats.service';
import { RedemptionCartDataService } from '../../../services/redemption-cart/redemption-cart-data.service';
import { environment } from '../../../../../environments/environment';
import {NGXLogger} from "ngx-logger";
import {RedirectModalComponent} from "../redirect-modal/redirect-modal.component";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";

/**
 * Navbar Component
 * holds alot of logic due to the content of the mini dashboard
 * also handles:
 * the cart flyout as child, logout/login functionality, effective account switch and role settings
 */
@Component({
  selector: 'neo-reward-engine-web-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * some user status variables, exposed to template via getters
   */
  private loggedIn$: boolean | undefined;
  private userName$: string | undefined;
  private isRegistered$: boolean | undefined;
  private status$: string | undefined;
  private _canRedeem = false;
  private modalOptions: NgbModalOptions = { centered: true, size: 'md' };

  subscriptions: Subscription = new Subscription();

  /**
   *
   * @param config injected to get necessary info for cas redirect url builder
   * @param router injected to create route based resizing of navbar
   * @param menuService injected to get menu points to build navigation
   * @param userStatsService injected to get general user data (e.g. if is enabled or not -> menu structure)
   * @param cartService injected to reset cart observables and retrigger simulation if user reloads on checkout page
   * @param userService injected for login and logout capability
   * @param programSettingsService injected to retrieve program settings e.g. if tier system is active
   * @param logger
   * @param modalService injected to redirect to eshop on contact form button
   */
  constructor(
    private readonly config: AuthConfigService,
    public readonly router: Router,
    public menuService: MenuService,
    public userStatsService: UserStatsService,
    public cartService: RedemptionCartDataService,
    private userService: UserService,
    private programSettingsService: ProgramSettingsService,
    private readonly logger: NGXLogger,
    private modalService: NgbModal
  ) {
    this.subscriptions.add(this.userStatsService.userContext$.subscribe({
      next: (userContext) => {
        this.loggedIn$ =
          userContext?.userInfo?.userType === 'Community' ||
          userContext?.userInfo?.userType === 'Admin';
        this.userName$ = userContext?.userInfo?.contactName;
        this.isRegistered$ = userContext?.rewardAccount?.status === 'Enabled';
        this.status$ = userContext?.userInfo?.status;
        this._canRedeem = userContext?.userInfo?.canRedeem ?? false;
        this.onResize();
        this.checkStickyConditions();
      },
    }));

    this.subscriptions.add(router.events.subscribe((val) => {
      this.checkStickyConditions();
      this.logger.debug('NavbarComponent.ctor() routerState', val, this.router.url);

      if (val instanceof NavigationStart) {
        if (this.router.url.includes('checkout')) {
          this.userStatsService.retrieveUserBalance();
          this.userStatsService.retrieveTierData();
          this.cartService.resetCartObservables();
        }

        // reset pageScroll if sticky header
        const myNavbar = document.getElementById('myNavbar');
        const myHeader = document.getElementById('myHeader');
        if (myNavbar && myHeader && myNavbar.classList.contains('isSticky')) {
          if (window.innerWidth > 1024) {
            const topScroll =
              myHeader.scrollHeight +
              (myNavbar.scrollHeight - myHeader.scrollHeight - 58);
            window.scrollTo({ top: topScroll });
          } else {
            const topScroll =
              myHeader.scrollHeight +
              (myNavbar.scrollHeight - myHeader.scrollHeight - 58 + 25);
            window.scrollTo({ top: topScroll });
          }
        }
      }
      // navigate back to overview if user tries to access tier system without enabled tiers in program
      if(val instanceof NavigationEnd){
        if(this.router.url.includes('tier') && !this.tierSystemActive){
          this.router.navigate(['overview']);
        }
      }
    }));

    this.userStatsService.retrieveUserBalance();
    this.subscriptions.add(this.userStatsService.userBalance$.subscribe(() => {
      this.onResize();
      this.checkStickyConditions();
    }));

    this.userStatsService.retrieveTierData();
    this.subscriptions.add(this.userStatsService.tierData$.subscribe(() => {
      this.onResize();
      this.checkStickyConditions();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get isSticky() {
    const myNavbar = document.getElementById('myNavbar');
    return myNavbar && myNavbar.classList.contains('isSticky');
  }

  get userState$(): Observable<UserContext | null> {
    return this.userStatsService.userContext$;
  }

  get loggedIn(): boolean | undefined {
    return this.loggedIn$;
  }

  get isRegistered(): boolean {
    return this.isRegistered$ || false;
  }
  get status(): string | undefined {
    return this.status$;
  }

  get userName() {
    return this.userName$;
  }

  get userBalance$() {
    return this.userStatsService.userBalance$;
  }

  get tierSystemActive() {
    return this.programSettingsService.programSettings$.value?.tierSystem.enabled;
  }

  get canRedeem(): boolean {
    return this._canRedeem;
  }

  /**
   * make sure to retrieve user context and resize flyout carts on mobile viewport
   */
  ngOnInit() {
    this.menuService.resizeFlyoutMobile(window.innerHeight);
    this.loggedIn$ = this.menuService.isLoggedIn;
    this.userStatsService.getUserContext();
  }
  /**
   * check and resize header if necessary to make sure header dashboard is displayed correctly
   */
  ngAfterViewInit() {
    this.onResize();
    this.checkStickyConditions();

    // add the sticky tracker
    const tid = setInterval(() => {
      const stickyElm = document.getElementById('myNavbar');

      const observer = new IntersectionObserver(
        ([e]) =>
          e.target.classList.toggle('isSticky', e.intersectionRatio < 0.75),
        { threshold: [0, 0.25, 0.5, 0.75, 1] }
      );
      if (stickyElm) {
        clearInterval(tid);
        observer.observe(stickyElm);
      }
    }, 10);
  }
  /**
   * login through cas via redirect url to overview page with user locale
   */
  public logIn(): void {
    redirectToCas(
      createIdmRedirectUrl(
        this.config.config.casBaseUrl,
        this.config.config.casClientId,
        `${window.location.href}`,
        this.config.config?.locale ?? 'en',
        this.config.config.idmRedirectUri
      )
    );
  }
  /**
   * TODO: CAS Single Sign Logout does not work properly (clarify why?)
   * TODO: We have to discuss in the context of HEI+ (and zaikio)
   * We will just destroy the user state for now
   */
  public logOut(): void {
    this.userService.logout();
    this.menuService.hoverAccount = false;
    this.menuService.mobileMenuToggleMyAccount = false;
    this.menuService.hoverAccount = false;
    window.location.href =
      `${environment.casBaseUrl}${environment.idmLogoutUrl}${document.location.origin}`;
  }
  /**
   * if the close event is triggered from mini cart component (click on cart button)
   * navigate to cart and close the flyout
   */
  closeMiniCartAndNavigateCart() {
    this.menuService.hoverShoppingCart = false;
    this.router.navigate(['/cart'], { queryParamsHandling: 'preserve' });
  }
  /**
   * resize the header elements depending on the viewport
   * calculation had to be done via typescript instead of css
   * because of the content size in the header dashboard
   */
  @HostListener('window:resize')
  onResize() {
    const tid = setInterval(() => {
      const myHeader = document.getElementById('myHeader');
      const myNavbar = document.getElementById('myNavbar');
      if (myHeader && myNavbar) {
        clearInterval(tid);
        if (window.innerWidth > 1024) {
          myNavbar.style.top =
            '-' + (myNavbar.scrollHeight - myHeader.scrollHeight - 70) + 'px';
        } else {
          myNavbar.style.top =
            '-' +
            (myNavbar.scrollHeight - myHeader.scrollHeight - 70 + 25) +
            'px';
        }
        this.adjustFooterSpacer();
      }
      this.menuService.resizeFlyoutMobile(window.innerHeight);
    }, 10);
  }
  /**
   * is used to determine if the user is on a page where the navbar should be auto sticky (no expanded header dashboard) or not
   */
  checkStickyConditions() {
    if (
      this.router.url.includes('cart') ||
      this.router.url.includes('register') ||
      this.router.url.includes('contact-form')
    ) {
      this.switchStickyNavbar(false);
    } else {
      this.switchStickyNavbar(true);
    }
  }

  /**
   * because of the way the header is build and the dynamic size of the elements
   * we can't stick the footer via css, so there is the footerSpacer div
   * which gets its height via this TS function
   */
  adjustFooterSpacer() {
    const footerSpacer = document.getElementById('footerSpacer');
    if (footerSpacer) {
      footerSpacer.style.height = '0px';

      if (document.body.scrollHeight < window.innerHeight) {
        footerSpacer.style.height =
          window.innerHeight - document.body.scrollHeight + 'px';
      } else if (document.body.scrollHeight > window.innerHeight) {
        footerSpacer.style.height = '0px';
        if (footerSpacer.style.height != ('0px' || undefined)) {
          this.adjustFooterSpacer();
        }
      }
    }
  }
  /**
   * switches the state of the navbar depending on how far the user has scrolled in the page and which site he is on
   * @param sticky wether or not the navbar should be switchted to sticky mode or non sticky mode
   */
  switchStickyNavbar(sticky: boolean) {
    const tid = setInterval(() => {
      const myHeader = document.getElementById('myHeader');
      const myNavbar = document.getElementById('myNavbar');
      const myContentContainer = document.getElementById('contentContainer');
      if (myHeader && myNavbar && myContentContainer) {
        clearInterval(tid);
        if (sticky) {
          myNavbar.style.position = 'sticky';
          myContentContainer.style.marginTop = '0px';
        } else {
          if (this.loggedIn) {
            myNavbar.style.position = 'fixed';
            if (window.innerWidth > 1024) {
              myContentContainer.style.marginTop = 70 + 'px';
            } else {
              myContentContainer.style.marginTop = 70 - 25 + 'px';
            }
          } else {
            myNavbar.style.position = 'fixed';
            myNavbar.style.marginTop = -25 + 'px';
            myContentContainer.style.marginTop = 70 - 25 + 'px';
          }
        }
        this.onResize();
      }
      this.menuService.resizeFlyoutMobile(window.innerHeight);
    }, 10);
  }
  /**
   *
   * @returns the route to be displayed in the header image link, is overview for logged in users only
   */
  getHeaderRouter(): string {
    if (this.loggedIn) {
      return '/overview';
    } else {
      return '';
    }
  }

  public onAccountSwitcherClose(): void {
    this.menuService.mobileMenuToggleMyAccount = !this.menuService.mobileMenuToggleMyAccount;
  }

  get mini(){
    return window.innerWidth == 1024;
  }

  openRedirectModal(){
    const modalRef = this.modalService.open(RedirectModalComponent,this.modalOptions);
    modalRef.componentInstance._redirectType = 'CONTACT';
  }
}
