/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionType } from './transactionType';
import { RewardTransactionUsage } from './rewardTransactionUsage';
import { TransactionStatus } from './transactionStatus';
import { RuleType } from './ruleType';


export interface RewardTransaction { 
    id?: string | null;
    sapAccountNumber: string;
    ssu: string;
    points: number;
    usages: Array<RewardTransactionUsage>;
    type: TransactionType;
    expiringDate: string;
    timestamp: string;
    comment: string;
    transactionStatus: TransactionStatus;
    ruleType?: RuleType;
    rewardOrderId?: string | null;
    /**
     * Flag set once the the user is notified about upcoming expiration. Only transaction of type reward use it.
     */
    expiryNotification?: boolean | null;
    orderId?: string | null;
    contactId?: string | null;
    /**
     * AdminUserId available in case the transaction was created/updated by an admin
     */
    adminUserId?: string | null;
}

