import { EnvironmentService } from './../../../services/environment/environment.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { loggerFn } from '../../../services/common-helper/pipe.helper';
import { StandardErrorHandlerService } from '../../../services/error-handler/standard-error-handler.service';
import { LoadIndicatorService } from '../../../services/load-indicator/load-indicator.service';
import { ToastService } from '../../../services/toast/toast.service';
import {
  CommunityUserService,
  UserPermissionDto,
} from '@neo-reward-engine-web/ecom-api';

/**
 * User Role Settings Modal Component
 * allows setting of canRedeem property for each user of effective account
 * if user is main contact of effective account
 * TODO: remove the direct api access from communityuserservice
 *       create service that handles this (e.g. RoleService)
 */
@Component({
  selector: 'neo-reward-engine-web-user-role-settings',
  templateUrl: './user-role-settings.component.html',
  styleUrls: ['./user-role-settings.component.scss'],
})
export class UserRoleSettingsComponent implements OnInit, OnDestroy {
  private _formResponsibles!: FormGroup;
  private _userPermissionDtos: UserPermissionDto[] = [];
  private userSubscription: Subscription = new Subscription();

  subscriptions: Subscription = new Subscription();

  /**
   *
   * @param modalService injected to close the modal from within
   * @param formBuilder
   * @param communityUserService injected to send the finished permission form request
   * @param loadIndicatorService injected to have spinner until submit request has response
   * @param toastService injected to give user feedback after sending settings request
   * @param logger
   * @param _errorHandler
   * @param environmentService injected to feed environment to user service
   */
  constructor(
    private modalService: NgbActiveModal,
    private readonly formBuilder: FormBuilder,
    private readonly communityUserService: CommunityUserService,
    private loadIndicatorService: LoadIndicatorService,
    private toastService: ToastService,
    private readonly logger: NGXLogger,
    private _errorHandler: StandardErrorHandlerService,
    private readonly environmentService: EnvironmentService
  ) {}

  get formResponsibles() {
    return this._formResponsibles;
  }
  get userPermissionDtos() {
    return this._userPermissionDtos;
  }

  close() {
    this.modalService.close();
  }
  /**
   * handles load indicator and sets the user permissions via communityuserservice and form data
   */
  onSubmit() {
    this.loadIndicatorService.showLoadIndicator();
    this.subscriptions.add(this.communityUserService
      .setUsers(this._userPermissionDtos)
      .pipe(
        finalize(() => {
          this.loadIndicatorService.hideLoadIndicator();
        })
      )
      .subscribe({
        next: (response) => {
          loggerFn(this.logger, 'CommunityUserService.setUsers successfull');
          this.toastService.showSuccessMessage(
            'header.userInfoLogin.userRoleSettings.successMsg'
          );
        },
        error: (error) => {
          this._errorHandler.handleError(
            error,
            'CommunityUserService.setUsers'
          );
          loggerFn(this.logger, 'CommunityUserService.setUsers failed');
        },
      }));
    this.modalService.close();
  }
  /**
   * modifies the accountuser redeem permissions from template click
   * @param checked wether or not the checkbox is checked (= user has redeem permission or not)
   * @param index index of account users array (to set only the changed user)
   */
  onCheckBoxChange(checked: boolean, index: number) {
    this._userPermissionDtos[index].canRedeem = checked;
  }

  /**
   * builds the form and create the usercontext subscription
   * to build the information of the accountusers for the form
   */
  ngOnInit() {
    this._formResponsibles = this.formBuilder.group({});
    this.userSubscription = this.communityUserService
      .geAccountUserPermissions()
      .subscribe({
        next: (userPermissionDtos: UserPermissionDto[]) => {
          if (userPermissionDtos) {
            this._userPermissionDtos = userPermissionDtos;
          }
          this._userPermissionDtos.forEach((v) => {
            //if(v.isMainContact){v.canRedeem = true};
            this._formResponsibles.addControl(
              this._userPermissionDtos.indexOf(v).toFixed(0),
              new FormControl(v.canRedeem)
            );
          });
        },
        error: (error) => {
          this._errorHandler.handleError(
            error,
            'CommunityUserService.accountUsers'
          );
          loggerFn(this.logger, 'CommunityUserService.accountUsers failed');
        },
      });
  }

  /**
   * make sure to remove subscriptions
   */
  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }
}
