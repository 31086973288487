/** navigation area definitions */
export type NavigationArea =
  | 'PUBLIC'
  | 'COMMUNITY'
  | 'ADMIN'
  | 'COMMUNITY-UNREGISTERED';

/** menuitem to build config */
export interface MenuItem {
  routerTarget: string;
  label: string;
}

/** menu interface to build config */
export interface Menu {
  navigationArea: NavigationArea;
  menuItems: MenuItem[];
}

/** menu configuration blueprint to choose from depending on user type */
export const menuConfiguration = new Map<NavigationArea, Menu>([
  [
    'PUBLIC',
    {
      navigationArea: 'PUBLIC',
      menuItems: [
        {
          label: 'menuBar.navLinkOverview',
          routerTarget: '/overview',
        },
      ],
    },
  ],
  [
    'COMMUNITY',
    {
      navigationArea: 'COMMUNITY',
      menuItems: [
        {
          label: 'menuBar.navLinkOverview',
          routerTarget: '/overview',
        },
        {
          label: 'menuBar.navLinkRedeemPoints',
          routerTarget: '/rewards',
        },
        {
          label: 'menuBar.navLinkTierStatus',
          routerTarget: '/reward-tier-status',
        },
        {
          label: 'menuBar.navLinkPointBalance',
          routerTarget: '/reward-point-history',
        },
      ],
    },
  ],
  [
    'COMMUNITY-UNREGISTERED',
    {
      navigationArea: 'COMMUNITY-UNREGISTERED',
      menuItems: [
        {
          label: 'menuBar.navLinkPointRegister',
          routerTarget: '/register',
        },
      ],
    },
  ],
]);
