import { Inject, Injectable } from '@angular/core';
import { AuthConfiguration } from './auth-config';
import { AUTH_CONFIG_TOKEN } from '@neo-reward-engine-web/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthConfigService {
  constructor(
    @Inject(AUTH_CONFIG_TOKEN) public readonly config: AuthConfiguration
  ) {}
}
