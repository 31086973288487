/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RewardAccountStatus } from './rewardAccountStatus';
import { Address } from './address';
import { SapAccountId } from './sapAccountId';


export interface RewardAccount {
    id?: string | null;
    ssu: string;
    currency: string;
    sapAccountNumber?: SapAccountId;
    billingAddress?: Address;
    phoneNumber?: string | null;
    status?: RewardAccountStatus;
    joinDate?: string;
    accountName?: string | null;
    accountTimeZone?: string | null;
}

