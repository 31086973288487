import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslocoService } from '@ngneat/transloco';
import { defaultToastParameter, ToastParameter } from './toast-config.model';

/**
 * toast service used application wide to spawn uniform an localized toast messages
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly hotToast: HotToastService,
    private readonly transloco: TranslocoService
  ) {}


  /** error message type bright red
   * @param translationKey transloco parameter
   * @param toastParameter optional toast parameter
   */
  public showErrorMessage(
    translationKey: string,
    toastParameter: ToastParameter = defaultToastParameter
  ): void {

    const sub= new Subscription();
    sub.add(this.processMessage(translationKey, toastParameter.placeholder).subscribe({
      next: (value) => {
        this.hotToast.error(value,{
          className: 'reward-toast-styles',
          style: {
            'background-color': '#F5501E',
            color: '#fff',
          },
          iconTheme: {
            primary: '#fff',
            secondary: '#F5501E',
          },
          autoClose: toastParameter.toastConfig.autoClose,
          dismissible: toastParameter.toastConfig.dismissible,
          duration: toastParameter?.toastConfig?.duration,
        })
        sub.unsubscribe();
      }
    }
    ))
  }
  /** success message type bright green
   * @param translationKey transloco parameter
   * @param toastParameter optional toast parameter
   */
  public showSuccessMessage(
    translationKey: string,
    toastParameter: ToastParameter = defaultToastParameter
  ): void {

    const sub= new Subscription();
    sub.add(this.processMessage(translationKey, toastParameter.placeholder).subscribe({
      next: (value) => {
        this.hotToast.success(value,      {
          className: 'reward-toast-styles',
          style: {
            'background-color': '#009b3c',
            color: '#fff',
          },
          iconTheme: {
            primary: '#fff',
            secondary: '#009b3c',
          },
          autoClose: toastParameter.toastConfig.autoClose,
          dismissible: toastParameter.toastConfig.dismissible,
          duration: toastParameter?.toastConfig?.duration,
        })
        sub.unsubscribe();
      }
    }
    ))
  }
  /** info message type primary blue
   * @param translationKey transloco parameter
   * @param toastParameter optional toast parameter
   */
  public showInfoMessage(
    translationKey: string,
    toastParameter: ToastParameter = defaultToastParameter
  ): void {

    const sub= new Subscription();
    sub.add(this.processMessage(translationKey, toastParameter.placeholder).subscribe({
      next: (value) => {
        this.hotToast.info(value,{
          className: 'reward-toast-styles',
          style: {
            'background-color': '#004178',
            color: '#fff',
          },
          iconTheme: {
            primary: '#fff',
            secondary: '#004178',
          },
          autoClose: toastParameter.toastConfig.autoClose,
          dismissible: toastParameter.toastConfig.dismissible,
          duration: toastParameter?.toastConfig?.duration,
        })
        sub.unsubscribe();
      }
    })
    )
  }

  private processMessage(
    translationKey: string,
    placeholder?: { [key: string]: string }
  ) : Observable<string> {
    return this.transloco.selectTranslate(translationKey, placeholder);
  }
}
