import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * pagination used on reward list component and transaction history
 */
@Component({
  selector: 'neo-reward-engine-web-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input()
  public page!: number;
  @Input()
  public itemCount!: number;
  @Input()
  public itemNumberOptions!: number[];
  @Input()
  public itemsPerPageSelection = 12;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemsPerPageChange: EventEmitter<number> =
    new EventEmitter<number>();

  ngOnInit() {
    if (this.itemNumberOptions) {
      this.itemsPerPageSelection = this.itemNumberOptions[0];
    }
  }

  get pageMax(): number {
    return Math.ceil(this.itemCount / this.itemsPerPageSelection);
  }

  /**
   *
   * @param newPage to what page to navigate
   */
  changePage(newPage: number) {
    this.page = newPage;
    this.pageChange.emit(this.page);
  }

  /**
   *
   * @param itemsPerPage how many items should now be on the page
   */
  changeItemsPerPage(itemsPerPage: number) {
    this.itemsPerPageSelection = itemsPerPage;
    this.itemsPerPageChange.emit(this.itemsPerPageSelection);
  }
}
