import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

/**
 * environment service to get environment dependent data like shopurl
 * TODO: build logic that builds shop url based on ssu and locale
 */
@Injectable({
  providedIn: 'root',
})

export class EnvironmentService {

  constructor(private readonly translocoService: TranslocoService) {
  }

  get backendUrl(): string {
    return environment.backendUrl;
  }

  get shopUrl(): string {
    const language = this.translocoService.getActiveLang();

    if (language === undefined) {
      return environment.shopUrl;
    }

    if (language.includes('US') && !(environment.shopUrl.includes('eshop.heidelberg.com'))) {
      return environment.shopUrl +'closedeshop?directLogin=true';
    }

    return environment.shopUrl + language.split('-')[1].toLowerCase()+'?directLogin=true';
  }
}
