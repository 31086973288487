/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RuleCondition = 'LoyaltyGreaterThan0' | 'LoyaltyGreaterThan50';

export const RuleCondition = {
    LoyaltyGreaterThan0: 'LoyaltyGreaterThan0' as RuleCondition,
    LoyaltyGreaterThan50: 'LoyaltyGreaterThan50' as RuleCondition
};

