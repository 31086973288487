export type UserType = 'Community' | 'Admin';

export interface UserState {
  contactName?: string;
  userType?: UserType;
  ssu?: string;
  sapAccountNumber?: string;
  accountName?: string;
  rewardPrograms?: string[] | null;
  status?: string;
  isMainContact?: boolean;
  isSuperAdmin?: boolean;
  canRedeem?: boolean;
  relatedSsu?: string[] | null;
  isUnauthorized?: boolean;
  isAuthenticated?: boolean;
}
