import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  AuthConfigService, createIdmRedirectUrl, redirectToCas,
} from '@neo-reward-engine-web/auth';
import { UserService } from '@neo-reward-engine-web/auth';


@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(
    private configuration: AuthConfigService,
    private userService: UserService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.userService.token$.value;

    // TODO: urlToExlude
    if (accessToken) {
      const header = 'Bearer ' + accessToken;
      req = req.clone({
        headers: new HttpHeaders({ Authorization: header }),
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpResponse<any>) => {
        if (error.status === 401 || error.status === 403) {
          const casURL = createIdmRedirectUrl(
            this.configuration.config.casBaseUrl,
            this.configuration.config.casClientId,
            this.configuration.config.redirectUri,
            this.configuration.config?.locale ?? 'en',
            this.configuration.config.idmRedirectUri
          );
          redirectToCas(casURL);
        }
        return throwError(() => error);
      })
    );
  }
}
