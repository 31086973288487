<div *transloco="let t;">
  <!-- Unregistered Info Banner-->
  <div class="container-fluid">
    <div class="row pt-5 pb-0 pb-lg-5">
      <div class="col-12 col-xl-4 mb-4 mb-xl-0">
        <div class="row">
          <div class="col-12">
            <div class="mini-dashboard__headline mini-dashboard__headline__blue">
              {{t('landing.unregistered.headline1')}}
            </div>
            <div class="mini-dashboard__textBox mini-dashboard__textBox__blue" style="min-height: 0px;">
              {{t('landing.unregistered.content1')}}
            </div>
          </div>
          <div class="col-12 col-sm-6 mt-3">
            <button class="btn btn-primary mb-0 w-100"
                    (click)="navigateToRegister()" [disabled]="loggedIn && !programIsActive">{{t(getRegisterButtonStatusTKey())}}</button>
          </div>
        </div>
      </div>

      <!-- Cube Flowchart Desktop -->
      <div class="d-none d-md-flex col-12 col-xl-8 mt-4 my-xl-auto  text-center" style = "justify-content: space-evenly;">

        <div class="col-4 col-md-2 ml-n4 p-0">
          <div class="bg-primary text-white d-inline-block overflow-hidden square-box-150">
            <div class="p-3 h3">
              {{t('landing.unregistered.flowDiagram.register')}}<br>
              <img class="mt-3" src="../../../../../../assets/images/Register-Rewards.svg"
                   height="60px" />
            </div>
          </div>
        </div>

        <div class="col-1 my-auto p-0 ml-n2">
          <i class="icon-collapse_right" style="font-size: 50px;"></i>
        </div>

        <div class="col-4 col-md-2 ml-n4 p-0">
          <div class="bg-primary text-white d-inline-block overflow-hidden square-box-150">
            <div class="p-3 h3">
              {{t('landing.unregistered.flowDiagram.purchase')}}<br>
              <i class="icon-shopping_cart text-white" style="font-size: 100px;"></i>
            </div>
          </div>
        </div>

        <div class="col-1 my-auto p-0 ml-n2">
          <i class="icon-collapse_right" style="font-size: 50px;"></i>
        </div>


        <div class="col-4 col-md-2 ml-n4 p-0">
          <div class="bg-primary text-white d-inline-block overflow-hidden square-box-150">
            <div class="p-3 h3">
              {{t('landing.unregistered.flowDiagram.collect')}}<br>
              <img class="mt-3" src="../../../../../../assets/images/Collect.svg"
                   style="height: auto; max-height:  60px;" />
            </div>
          </div>
        </div>

        <div class="col-1 my-auto p-0 ml-n2">
          <i class="icon-collapse_right" style="font-size: 50px;"></i>
        </div>


        <div class="col-4 col-md-2 ml-n4 p-0">
          <div class="bg-primary text-white d-inline-block overflow-hidden square-box-150">
            <div class="p-3 h3">
              {{t('landing.unregistered.flowDiagram.redeem')}}<br>
              <i class="icon-gift text-white" style="font-size: 100px;"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Tripple Flowchart Mobile Vertical -->
      <div class="d-block d-md-none col-12 text-center mt-4 mt-xl-auto">
        <div class="row">
          <!-- 1 -->
          <div class="col-4 px-0 bg-primary">
            <div class="d-flex square-box-150-h-only">
              <img src="../../../../../../assets/images/Register-Rewards.svg"
                   style="z-index: 15; height: auto; position: relative;margin:auto;" />
            </div>
          </div>
          <div class="col-2 pl-0">
            <span class='tip tip__right tip__blue'></span>
          </div>
          <div class="col-6 my-auto">
            <div class="h2 text-primary h2--bold">{{t('landing.unregistered.flowDiagram.register')}}</div>
            <div class="p text--blue">{{t('landing.unregistered.flowDiagram.registerText')}}</div>
          </div>
          <!-- 2 -->
          <div class="col-6 my-auto">
            <div class="h2 text-primary h2--bold">{{t('landing.unregistered.flowDiagram.purchase')}}</div>
            <div class="p text--blue">{{t('landing.unregistered.flowDiagram.purchaseText')}}</div>
          </div>
          <div class="col-2 px-0">
            <span class='tip tip__left tip__green'></span>
          </div>
          <div class="col-4 px-0" style="background-color: var(--green);">
            <div class="d-flex square-box-150-h-only">
              <img  src="../../../../../../assets/images/Shopping_Cart.svg"
                    style="z-index: 15; height: auto; position: relative;margin:auto;max-width: 100%;" />
            </div>
          </div>
          <!-- 3 -->
          <div class="col-4 px-0" style="background-color: var(--yellow);">
            <div class="d-flex square-box-150-h-only">
              <img  src="../../../../../../assets/images/Collect.svg"
                    style="z-index: 15; height: auto; position: relative;margin:auto;" />
            </div>
          </div>
          <div class="col-2 pl-0">
            <span class='tip tip__right tip__yellow'></span>
          </div>
          <div class="col-6 my-auto">
            <div class="h2 text-primary h2--bold">{{t('landing.unregistered.flowDiagram.collect')}}</div>
            <div class="p text--blue">{{t('landing.unregistered.flowDiagram.collectText')}}</div>
          </div>
          <!-- 4 -->
          <div class="col-6 my-auto">
            <div class="h2 text-primary h2--bold">{{t('landing.unregistered.flowDiagram.redeem')}}</div>
            <div class="p text--blue">{{t('landing.unregistered.flowDiagram.redeemText')}}</div>
          </div>
          <div class="col-2 px-0">
            <span class='tip tip__left tip__cyan'></span>
          </div>
          <div class="col-4 px-0" style="background-color: var(--cyan);">
            <div class="d-flex square-box-150-h-only">
              <img  src="../../../../../../assets/images/Gift.svg"
                    style="z-index: 15; height: auto; position: relative;margin:auto;max-width: 100%;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Sample Rewards -->
  <div style="background-color: #F3F4F8;">

    <div class="row no-gutters align-items-center py-4">
      <!-- <div class="d-none d-sm-block col">
        <hr class="bg-primary border-primary">
      </div> -->
      <!-- <div class="col-12 col-sm-6 col-xl-3 text-center">
        <h1 class="text-primary text--bold">{{t('landing.unregistered.possibleRewards')}}*</h1>
      </div> -->
      <!-- <div class="d-none d-sm-block col">
        <hr class="bg-primary border-primary">
      </div> -->
    </div>

    <!-- <div class="container-fluid">

      <div class="row">
        <div class="col-md-6 col-xl-4">
          <div class="w-100 bg-primary text-white overflow-hidden">
            <div
              class="row no-gutters w-100 align-items-center bg-white overflow-hidden position-relative landing-tile-big-image-container">
              <img class="w-100 h-100 position-absolute"
                   src="../../../../../../assets/images/appleProducts.jpg" style="object-fit: cover;">
            </div>
          </div>
          <div class="py-2 pl-4 bg-primary text-white " style="height: fit-content;">
            {{t('landing.unregistered.appleProducts')}}</div>
        </div>
        <div class="col-md-6 col-xl-4 mt-4 mt-md-0">
          <div class="w-100 bg-primary text-white overflow-hidden">
            <div
              class="row no-gutters w-100 align-items-center bg-white overflow-hidden landing-tile-big-image-container">
              <img class="w-100" src="../../../../../../assets/images/heidelbergMerch.jfif">
            </div>
          </div>
          <div class="py-2 pl-4 bg-primary text-white " style="height: fit-content;">
            {{t('landing.unregistered.merchandise')}}</div>
        </div>
        <div class="col-md-12 col-xl-4">
          <div class="row">
            <div class="col-12 col-md-6 col-xl-12 mt-4 mt-xl-0">
              <div class="w-100 bg-primary text-white overflow-hidden">
                <div
                  class="row no-gutters w-100 align-items-center bg-white overflow-hidden landing-tile-small-image-container">
                  <img class="w-100" src="../../../../../../assets/images/coupon.png">
                </div>
              </div>
              <div class="py-2 pl-4 bg-primary text-white " style="height: fit-content;">
                {{t('landing.unregistered.coupons')}}</div>
            </div>
            <div class="d-none d-xl-flex landing-tile-small-image-container__spacer">&nbsp;</div>
            <div class="col-12 col-md-6 col-xl-12 mt-4 mt-xl-0">
              <div class="w-100 overflow-hidden">
                <div
                  class="row no-gutters w-100 align-items-center bg-white overflow-hidden landing-tile-small-image-container">
                  <img class="w-100" src="../../../../../../assets/images/goPro.jpg"
                       style="margin-top: -10%;">
                </div>
              </div>
              <div class="py-2 pl-4 bg-primary text-white " style="height: fit-content;">
                {{t('landing.unregistered.otherTech')}}</div>
            </div>
          </div>
        </div>

        <div class="col-12 py-3 font-italic text-primary">
          *{{t('landing.unregistered.disclaimer')}}
        </div>

      </div>
    </div> -->
  </div>
</div>
