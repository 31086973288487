/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionStatus } from './transactionStatus';
import { RuleType } from './ruleType';


export interface AdminTransactionModel { 
    id?: string | null;
    points: number;
    comment: string;
    transactionStatus: TransactionStatus;
    ruleType: RuleType;
    orderId?: string | null;
    expiringDate?: string | null;
}

