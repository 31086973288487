import { MultiCurrencyService } from '../../../services/multi-currency/multi-currency.service';
import { ProgramSettingsService } from '../../../services/program-settings/program-settings.service';
import { Component, Input } from '@angular/core';
import { RedemptionCartDataService } from '../../../services/redemption-cart/redemption-cart-data.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProductDetailPageComponent } from '../product-detail-page/product-detail-page.component';
import { RewardProductDto } from '@neo-reward-engine-web/ecom-api';
import { UserStatsService } from '../../../services/user-stats/user-stats.service';
import {NGXLogger} from "ngx-logger";

/**
 * reward gridview item component gets spawned by reward gridview component on reward catalogue page
 * has add to cart functionality and product detail modal if activated
 * TODO: implement activation/deactivation of product detail modal
 */
@Component({
  selector: 'neo-reward-engine-web-reward-gridview-item',
  templateUrl: './reward-gridview-item.component.html',
  styleUrls: ['./reward-gridview-item.component.scss'],
})
export class RewardGridviewItemComponent {
  @Input()
  public productItem!: RewardProductDto;

  public showAnimation = false;
  public animationTimeout: ReturnType<typeof setTimeout> | undefined;
  public modalOptions: NgbModalOptions = { centered: true, size: 'lg' };

  /**
   *
   * @param cartDataService for add to cart functionality
   * @param modalService to open product detail page modal
   * @param programSettingsService injected to retrieve basic program settings e.g. if productdetails should be shown
   * @param userService injected to retrieve user context
   * @param multiCurrencyService injected to get localized currency for currency pipe
   * @param logger injected for logging
   */
  constructor(
    public readonly cartDataService: RedemptionCartDataService,
    private readonly modalService: NgbModal,
    private readonly programSettingsService: ProgramSettingsService,
    private readonly userService: UserStatsService,
    private readonly multiCurrencyService: MultiCurrencyService,
    private readonly logger: NGXLogger
  ) {}

  get imageUrl() {
    return (
      this.productItem.productImages?.[0] ||
      '../../../../../assets/images/no-image.jpg'
    );
  }

  get locale() {
    return this.multiCurrencyService.locale;
  }
  get currency() {
    return this.multiCurrencyService.currencyCode;
  }
  get currFormat() {
    return this.multiCurrencyService.currencyFormat;
  }

  get hasCoPayment() {
    return this.programSettingsService.programSettings$.value?.copayment;
  }

  get userContext$(){
    return this.userService.userContext$;
  }

  /** opens product detail page modal with product item info */
  open(): void {
    this.logger.debug('RewardGridviewItemComponent.open() called');
    if (this.programSettingsService.programSettings$.value?.productDetailPage) {
      const modalRef = this.modalService.open(
        ProductDetailPageComponent,
        this.modalOptions
      );
      modalRef.componentInstance.productItem = this.productItem;
    }
  }
  /** adds product to cartdataservice */
  addToCart(productItem: RewardProductDto) {
    this.showAnimation = true;
    this.animationTimeout = setTimeout(() => {
      this.showAnimation = false;
      this.cartDataService.rewardProductToCart(productItem, 1);
    }, 1750);
  }

  localeNameOfProductItem(productItem: RewardProductDto, locale: string ): string {
    const name = productItem.name.find(x => x.context === locale)?.value ?? '';
    if (name.length > 50) {
      return `${name.substring(0, 50)}...`;
    }
    return name;
  }

  nonCashBenefitOfProductItem(productItem: RewardProductDto): number {
    return productItem.nonCashBenefit.find(x => x.currency === this.multiCurrencyService.currencyCode)?.value || 0;
  }
}
