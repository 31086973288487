<div class="product-tile text--blue"  *transloco="let translate;" >
    <div class="mx-0">
        <div class="teaser__label_tile">
        </div>
        <div class="col-12 img-wrapper d-flex align-items-center justify-content-center">
            <a class="text-decoration-none">
                <img class="product-tile__image d-block mx-auto my-4 d-lg-inline"
                    src="{{ productItem.productImages?.[0] || '/assets/images/no-image.jpg'}}"></a>

            <div class="product__badgeBox product__badgeBox--listView list-position-right">
            </div>
        </div>
        <div class="col-12 product-tile__descBox">
            <p class="h5 product-tile__name mb-0">
                <a class="text-decoration-none"
                   *ngIf="userContext$ | async; let userContext;"
                   (click)="open()">
                    {{localeNameOfProductItem(productItem, userContext.userInfo!.language! )}}
                </a>
            </p>
        </div>
        <div class="col-12">
            <div class="product-tile__pricebox d-none">
                <p class="mt-3 product-tile__pricebox product-tile__pricebox__price">
                    <span class="productPriceText">{{ translate('rewards.productTile.noPriceData')}}</span>
                </p>
            </div>
        </div>
        <div class="product-tile__quantity-picker-box d-none">
            <p class="product-tile__quantity-picker-box__label d-inline-block mb-0 mx-1">
                {{ translate('rewards.productTile.quantity')}}
            </p>
            <div class="cc_qty_control_row row">
                <div class="form-group input-group">
                    <input type="text" class="input-text qty entry form-control text-center">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            / {{ translate('rewards.productTile.pieceAbrv')}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <p class="mt-3 product-tile__pricebox product-tile__pricebox__price">
                <span class="productPriceText">{{productItem.points}} {{ translate('rewards.productTile.points') }}</span>
            </p>
          <p class="product-tile__SKU" [style.visibility]="hasCoPayment ? 'visible' : 'hidden'">
                <span *ngIf="nonCashBenefitOfProductItem(productItem); let value;">
                    +&nbsp;{{
                    value |currency: currency
                    :'symbol':'1.2-2'
                    :locale
                    }} {{ translate('rewards.productTile.copayment') }}
                </span>
          </p>

        </div>
        <!--********-->
        <div class="col-12 align-self-end">
            <div class="listCartButton">
                <button [disabled]="showAnimation"
                    class="btn btn-lg btn-primary btn-block d-flex justify-content-center align-items-center btn--var-text-icon mt-3 mt-lg-auto"
                    (click)="addToCart(productItem)">
                    <div *ngIf="!showAnimation"
                        class="align-items-center d-flex justify-content-around position-relative">
                        <i class="icon-shopping_cart"></i>
                        <span>{{ translate('rewards.productTile.addToCart') }}</span>
                    </div>
                    <div *ngIf="showAnimation"
                        class="align-items-center d-flex justify-content-around position-relative">
                        <lottie-player src="../../../../assets/lotties/addToCart-lottie.json" background="transparent"
                            style="height: 60px;" speed="1" autoplay>
                        </lottie-player>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
