<div *transloco="let t" class="text--blue pt-3 pb-3">

    <div class="col-12  px-0">
        <div class=" d-inline-block">
            <div class="h1 h1--bold mb-4 mb-md-0 pr-0 d-block">
                {{t('header.userInfoLogin.effectiveAccount.headline')}}
            </div>
        </div>
        <i (click)="close()" style="cursor: pointer;float:right;" class="icon icon-close"></i>
    </div>


    <div class="row mt-3">
        <div class="col-12" style="flex-wrap: wrap;">
            {{t('header.userInfoLogin.effectiveAccount.infoText')}}
        </div>
    </div>
    <hr>

    <div class="row">
        <div class=" col-6 h3 h3--bold"> {{t('header.userInfoLogin.effectiveAccount.accountName')}}</div>
    </div>

    <div style="max-height: 200px;" [ngClass]="{'scrollable': userContext.userInfo?.relatedAccounts!.length > 6}">
        <div class="ml-2" *ngFor="let account of userContext.userInfo?.relatedAccounts;let i = index;"
            (click)="selectEffectiveAccount(account.sapAccountNumber?.value ?? '')">
            <div class="row accountSelection"
                [ngClass]="{'accountSelection__selected': userContext.rewardAccount?.sapAccountNumber === account.sapAccountNumber}">
                <div class="col-6 mt-2">
                    <div>{{account.name}}</div>
                </div>
                <div class="col6 mt-2">
                    <div>({{account.sapAccountNumber}})</div>
                </div>
            </div>
        </div>
    </div>
</div>
