import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService, UserState} from "@neo-reward-engine-web/auth";
import {NGXLogger} from "ngx-logger";
import {map} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";


/**
 * Auth Guard that check if logged in user is a community user and is allowed to
 * navigate inside the community page.
 */

@Injectable({
  providedIn: 'root'
})
export class AuthGuardCommunityGuard implements CanActivate {

  /**
   *
   * @param userService injected to check usertype
   * @param router
   * @param logger
   */
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {
    //
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.logger.debug('AuthGuardCommunityGuard.canActivate() with url: ', window.location.href);
    return this.userService.userState$.pipe(
      map((state: UserState | null) => {
        return !!(state &&
          state.userType === 'Community' &&
          state.status === 'Enabled' &&
          state.canRedeem);
      })
    );
  }
}
