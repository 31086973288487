import { UserContext } from '@neo-reward-engine-web/ecom-api';
import {Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {UserService} from "@neo-reward-engine-web/auth";
import {AccountSwitchService} from "../../../services/account-switch/account-switch.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
/**
 * user effective account switch modal component
 */
@Component({
  selector: 'neo-reward-engine-web-user-account-switch',
  templateUrl: './user-account-switch.component.html',
  styleUrls: ['./user-account-switch.component.scss'],
})
export class UserAccountSwitchComponent {
  /**
   * gets input from navbar components (e.g. normal or mobile)
   * so that modal doesnt re-retrieve the possible accounts
   */
  @Input()
  userContext!: UserContext;

  @Input()
  close$: Subject<boolean> | undefined;


  /**
   *
   * @param modalService injected to be able to close the modal again
   * @param userService User Service to update User Info
   * @param accountSwitchService Account Switch option
   * @param router
   */
  constructor(
    private modalService: NgbActiveModal,
    private readonly userService: UserService,
    private readonly accountSwitchService: AccountSwitchService,
    private readonly router: Router,
  ) {}


  close() {
    this.close$?.next(true);
    this.modalService.close();
  }
  /**
   * loads the page new with effective account id query parameter in url
   * -> gets picked up by interceptor and refreshes user countext with different account
   * @param accountId selected account id from template selection
   */
  async selectEffectiveAccount(accountId: string) {
    await this.accountSwitchService.switchAccount(accountId);
    await this.router.navigate(['overview']);

    this.close$?.next(true);
    this.modalService.close();
  }
}
