import {UserState} from "@neo-reward-engine-web/auth";
import {PublicUserState} from "../common-helper/user-state.type";

/**
 * ResolverHandler
 *
 * Determines the UserState out of the information gathered in the login
 * process.
 *
 */
export class ResolverHandler {
  private _publicUserState: PublicUserState = 'NOT_AUTHENTICATED';
  constructor(
    private state: UserState | null,
  ) {
    this.calculateUserState();
  }

  get publicUserState(): PublicUserState {
    return this._publicUserState;
  }

  private calculateUserState(): void {
    if (this.state === null) {
      this._publicUserState = 'NOT_AUTHENTICATED';
      return;
    } else if (this.state?.userType === 'Admin') {
      this._publicUserState = 'AUTHENTICATED_ADMIN';
      return;
    } else if (this.state.isUnauthorized && this.state.isAuthenticated) {
      this._publicUserState = 'AUTHENTICATED_NO_ACCOUNT';
      return;
    } else if (
      this.state.userType === 'Community' &&
      this.state.status === 'Enabled' &&
      this.state.canRedeem
    ) {
      this._publicUserState = 'AUTHENTICATED_ENABLED';
      return;
    } else if (
      this.state.userType === 'Community' &&
      (this.state.status === 'WaitingForApproval' ||
        this.state.status === 'Disabled' ||
        this.state.status === 'Declined')
    ) {
      this._publicUserState = 'AUTHENTICATED_NOT_ENABLED';
      return;
    } else if (
      this.state.userType === 'Community' &&
      this.state.status === 'Unregistered'
    ) {
      this._publicUserState = 'AUTHENTICATED_TO_REGISTER';
      return;
    } else if (
      this.state.userType === 'Community' &&
      this.state.status === 'Enabled' &&
      !this.state.canRedeem
    ) {
      this._publicUserState = 'AUTHENTICATED_ENABLED_NO_REDEEM';
      return;
    } else {
      this._publicUserState = 'NOT_AUTHENTICATED';
    }
  }
}
