import { Component } from '@angular/core';
import { MenuService } from '../../../../services/menu/menu.service';
/**
 * display component for the text in the header dashboard
 * (e.g. heidelberg rewards explanation)
 */
@Component({
  selector: 'neo-reward-engine-web-mini-dashboard-textbox',
  templateUrl: './mini-dashboard-textbox.component.html',
  styleUrls: ['./mini-dashboard-textbox.component.scss'],
})
export class MiniDashboardTextboxComponent {
  /**
   *
   * @param menuService injects menu service to get generic route translation key to be used in template
   */
  constructor(private menuService: MenuService) {}

  get headerTranslationKey() {
    return this.menuService.getRouterTranslation('header.miniDashboard.');
  }
}
