<ng-container  *transloco="let t;">
  <div class="loading-spinner__main" *ngIf="loadIndicatorService.isVisible">
    <div class="loading-spinner__wrapper">
      <div class="loading-spinner__spinner">
        <i class="fas fa-sync fa-spin fa-4x"></i>
      </div>
      <div class="loading-spinner__label mt-2">
        {{ t('shared.loadingSpinner') }}
      </div>
    </div>
  </div>
</ng-container>
