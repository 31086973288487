/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ErrorType } from './errorType';
import { HttpStatusCode } from './httpStatusCode';


export interface RewardEngineError { 
    type?: ErrorType;
    title?: string | null;
    status?: HttpStatusCode;
    detail?: string | null;
    instance?: string | null;
}

