/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ErrorType = 'UnexpectedError' | 'InvalidModel' | 'RewardServiceError' | 'CollectPointsError' | 'MongoRepositoryError' | 'EmailRepositoryError' | 'HttpContextError' | 'SalesForceAuthenticationError' | 'SfClientQueryError' | 'RedeemError' | 'UserRepository' | 'BlobStorageError' | 'SchedulerError' | 'MailComposeError' | 'AuthenticationError' | 'OrderError' | 'RewardAccountError' | 'RewardUserError' | 'RedisError' | 'RewardRepositoryError' | 'AccountsServiceError' | 'UserService' | 'RulesService' | 'TransactionsService';

export const ErrorType = {
    UnexpectedError: 'UnexpectedError' as ErrorType,
    InvalidModel: 'InvalidModel' as ErrorType,
    RewardServiceError: 'RewardServiceError' as ErrorType,
    CollectPointsError: 'CollectPointsError' as ErrorType,
    MongoRepositoryError: 'MongoRepositoryError' as ErrorType,
    EmailRepositoryError: 'EmailRepositoryError' as ErrorType,
    HttpContextError: 'HttpContextError' as ErrorType,
    SalesForceAuthenticationError: 'SalesForceAuthenticationError' as ErrorType,
    SfClientQueryError: 'SfClientQueryError' as ErrorType,
    RedeemError: 'RedeemError' as ErrorType,
    UserRepository: 'UserRepository' as ErrorType,
    BlobStorageError: 'BlobStorageError' as ErrorType,
    SchedulerError: 'SchedulerError' as ErrorType,
    MailComposeError: 'MailComposeError' as ErrorType,
    AuthenticationError: 'AuthenticationError' as ErrorType,
    OrderError: 'OrderError' as ErrorType,
    RewardAccountError: 'RewardAccountError' as ErrorType,
    RewardUserError: 'RewardUserError' as ErrorType,
    RedisError: 'RedisError' as ErrorType,
    RewardRepositoryError: 'RewardRepositoryError' as ErrorType,
    AccountsServiceError: 'AccountsServiceError' as ErrorType,
    UserService: 'UserService' as ErrorType,
    RulesService: 'RulesService' as ErrorType,
    TransactionsService: 'TransactionsService' as ErrorType
};

