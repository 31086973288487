import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { getRangeOfMonths } from '../helper-util.ts/date-helper';
import { UserStatsService } from '../../services/user-stats/user-stats.service';
import { RewardTransactionViewModel } from '../../services/reward/reward-transaction-view.model';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/** month data interface for easier handling of ranges */
interface monthsData {
  monthName: string;
  monthYear: number;
  isInRange: boolean;
  isLowerEdge: boolean;
  isUpperEdge: boolean;
  isHovered: boolean;
}

/**
 * month range picker component used in reward point history chart
 */
@Component({
  selector: 'neo-reward-engine-web-month-range-picker',
  templateUrl: './month-range-picker.component.html',
  styleUrls: ['./month-range-picker.component.scss'],
})
export class MonthRangePickerComponent implements OnInit, OnDestroy {
  private rewardTransactions$: Observable<
    RewardTransactionViewModel[] | undefined
  >;
  private minShownYear: number = new Date().getFullYear();
  private _years: Array<number> = [];
  private _months: Array<string> = [];
  private _monthsDataArray: Array<monthsData> = [];
  private _rangeIndexes: Array<number | null> = [];
  private _monthViewSlicesIndexes: Array<number> = [];
  private _currentYearIndex = 0;
  private _globalIndexOffset = 0;
  private _monthDataSlice: Array<monthsData> = [];
  private _hoverToggle = false;

  @Output() dateRangeSelected = new EventEmitter();

  subscriptions: Subscription = new Subscription();

  /**
   *
   * @param userStatsService injected to retrieve reward transactions
   */
  constructor(readonly userStatsService: UserStatsService) {
    this.rewardTransactions$ = this.userStatsService.rewardTransactions$;
  }

  ngOnInit() {
    this.getMinYear();
    this.initYearLabels();
    this.initMonthLabels();
    this._currentYearIndex = this._years.findIndex(
      (year) => year === new Date().getFullYear()
    );
    this.initViewSlices();
    this.initMonthsData();
    this.initRangeIndexes();
    this.sliceDataIntoView();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get years() {
    return this._years;
  }
  get rangeIndexes() {
    return this._rangeIndexes;
  }
  get currentYearIndex() {
    return this._currentYearIndex;
  }
  get globalIndexOffset() {
    return this._globalIndexOffset;
  }
  get monthDataSlice() {
    return this._monthDataSlice;
  }

  getMinYear() {
    this.subscriptions.add(this.rewardTransactions$
      .pipe(
        map((transactions) => {
          if (transactions) {
            this.minShownYear = Math.min(
              ...transactions.map((transaction) => transaction.timeStamp.year)
            );
          }
        })
      )
      .subscribe());
  }

  initYearLabels() {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );

    this._years = range(this.minShownYear, currentYear, 1);
  }

  initMonthLabels() {
    this._months = new Array(12).fill(0).map((_, i) => {
      return new Date(`${i + 1}/1`).toLocaleDateString('en', {
        month: 'short',
      });
    });
  }

  initViewSlices() {
    this._monthViewSlicesIndexes = [];

    this._years.forEach((year, index) => {
      if (index === 0) {
        this._monthViewSlicesIndexes.push(0);
      } else if (index === 1) {
        this._monthViewSlicesIndexes.push(12);
      } else
        this._monthViewSlicesIndexes.push(
          this._monthViewSlicesIndexes[index - 1] + 12
        );
    });
  }

  initMonthsData() {
    this._years.forEach((year) => {
      this._months.forEach((month) => {
        this._monthsDataArray.push({
          monthName: month,
          monthYear: year,
          isInRange: false,
          isLowerEdge: false,
          isUpperEdge: false,
          isHovered: false,
        });
      });
    });
  }

  initRangeIndexes() {
    this._rangeIndexes = [null, null];
  }

  sliceDataIntoView() {
    this._globalIndexOffset =
      this._monthViewSlicesIndexes[this._currentYearIndex];
    this._monthDataSlice = this._monthsDataArray.slice(
      this._globalIndexOffset,
      this._globalIndexOffset + 12
    );
  }

  toggleHoverToggle() {
    this._hoverToggle = !this._hoverToggle;
  }

  incrementYear() {
    if (this._currentYearIndex !== this._years.length - 1) {
      this._currentYearIndex++;
      this.sliceDataIntoView();
    }
  }

  decrementYear() {
    if (this._currentYearIndex !== 0) {
      this._currentYearIndex--;
      this.sliceDataIntoView();
    }
  }

  setHoverProperty(_event: Event, _monthsData: monthsData, hoveredIndex: number) {
    if (this._hoverToggle) {
      const startIndex = this._monthsDataArray.findIndex(
        (month) => month.isLowerEdge === true
      );
      this._globalIndexOffset =
        this._monthViewSlicesIndexes[this._currentYearIndex];
      const hoveredMonth = Array<monthsData>();

      this._monthsDataArray.forEach((month, index) => {
        if (
          (index > startIndex &&
            index <= hoveredIndex + this._globalIndexOffset) ||
          (index < startIndex && index >= hoveredIndex + this.globalIndexOffset)
        ) {
          hoveredMonth.push(month);
          month.isHovered = true;
        }
      });

      this._monthsDataArray.forEach((month) => {
        if (!hoveredMonth.includes(month)) {
          month.isHovered = false;
        }
      });
    }
  }

  onClick(indexClicked: number) {
    // loop to pick first date (start)
    if (this._rangeIndexes[0] === null) {
      // position of clicked element in monthsDataArray
      this._rangeIndexes[0] = indexClicked + this._globalIndexOffset;
      // set clicked item to lowerEdge (for hover purpose)
      this._monthsDataArray[this._rangeIndexes[0]].isLowerEdge = true;
      // toggle hover state to true (while searching end date (upperEdge))
      this.toggleHoverToggle();
    }
    // loop to pick second date (end)
    else if (this._rangeIndexes[1] === null) {
      this._rangeIndexes[1] = indexClicked + this._globalIndexOffset;
      // sort range indexes to enable pick forward and backward both return sorted date range
      this._rangeIndexes.sort((a, b) => {
        if (a != null && b != null) {
          return a - b;
        } else {
          return 0;
        }
      });
      // toggle hover state to false
      this.toggleHoverToggle();

      // set state of month in picked date range to inRange or lowerEdge/upperEdge
      this._monthsDataArray.forEach((month, index) => {
        if (this._rangeIndexes[0] != null && this._rangeIndexes[1] != null) {
          if (
            this._rangeIndexes[0] <= index &&
            index <= this._rangeIndexes[1]
          ) {
            month.isInRange = true;
          }
          if (this._rangeIndexes[0] === index) {
            month.isLowerEdge = true;
          }
          if (this._rangeIndexes[1] === index) {
            month.isUpperEdge = true;
          }
        }
      });
      this.emitDateRange();
    }
    // loop if date range is already picked
    else {
      // set rangeIndexes to [null,null] -> resets picker
      this.initRangeIndexes();
      // reset every element to initial state
      this._monthsDataArray.forEach((a) => {
          a.isInRange = false;
          a.isLowerEdge = false;
          a.isUpperEdge = false;
          a.isHovered = false;
      });
      // if date range is picked get new start date with next click
      this.onClick(indexClicked);
    }
  }

  emitDateRange() {
    if (this._rangeIndexes[0] != null && this._rangeIndexes[1] != null) {
      const fromMonthYear = this._monthsDataArray[this._rangeIndexes[0]];
      const toMonthYear = this._monthsDataArray[this._rangeIndexes[1]];
      const from = new Date(
        `${fromMonthYear.monthYear} ${fromMonthYear.monthName}`
      );
      const to = new Date(`${toMonthYear.monthYear} ${toMonthYear.monthName}`);
      const dateRange = getRangeOfMonths(from, to);
      this.dateRangeSelected.emit(dateRange);
    }
  }
}
