import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@neo-reward-engine-web/auth';
import { ToastService } from '../toast/toast.service';
import { MenuService } from '../menu/menu.service';
import { MultilanguageService } from '../multilanguage/multilanguage.service';
import {environment} from "../../../../environments/environment";
import {map, take, tap} from "rxjs/operators";
import {NGXLogger} from "ngx-logger";
import {loggerFn} from "../common-helper/pipe.helper";
import {ResolverHandler} from "./resolver.handler";
import {
  handleAdminUser, handleCommunityPublic,
  handleCommunityUserEnabled, handleCommunityUserUnregistered,
  showNoRewardUserToast
} from "../common-helper/resolver-guard.helper";

/**
 * resolves for the landing page
 * targets all user states (admin, community, logged in, logged out) because of how the landing page
 * was origally routed on "/"
 * TODO: clean up since splitting routing to "/overview" and "/"
 */
@Injectable({
  providedIn: 'root',
})
export class PublicResolver implements Resolve<boolean> {
  constructor(
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly menuService: MenuService,
    private readonly multiLanguageService: MultilanguageService,
    private readonly logger: NGXLogger,
  ) {
    //
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.multiLanguageService.setLanguage();

    return this.userService.userState$.pipe(
      take(1), // just to ensure that the subject does ever complete and not keep the resolver open forever
      loggerFn(this.logger, 'PublicResolver.resolve()'),
      map(state => {

        const loginState = new ResolverHandler(state);
        switch (loginState.publicUserState) {
          case 'AUTHENTICATED_NO_ACCOUNT':
            showNoRewardUserToast(this.toastService);
            handleCommunityPublic(this.menuService);
            break;
          case 'AUTHENTICATED_ADMIN':
            handleAdminUser(
              environment.redirectUri,
              environment.rewardTokenStorageKey,
              environment.rewardUserStorageKey,
              environment.adminRedirectUrl
            );
            break;
          case 'AUTHENTICATED_ENABLED':
            handleCommunityUserEnabled(this.menuService);
            break;
          case 'AUTHENTICATED_ENABLED_NO_REDEEM':
            handleCommunityPublic(this.menuService);
            break;
          case 'AUTHENTICATED_NOT_ENABLED':
            handleCommunityPublic(this.menuService);
            break;
          case 'AUTHENTICATED_TO_REGISTER':
            handleCommunityUserUnregistered(this.menuService);
            break;
          default:
            handleCommunityPublic(this.menuService);
        }
        return true;
      })
    );
  }
}
