export interface AuthConfiguration {
  casBaseUrl: string;
  idmLogoutUrl: string;
  idmAccessTokenUrl: string;
  idmRedirectUri: string;
  idmAdminRedirectUri: string;
  casClientId: string;
  redirectUri: string;
  locale?: string;
  secret: string;
  publicUrls: string[];
  backendUrl: string;
  rewardTokenStorageKey: string;
  rewardUserStorageKey: string;
}
