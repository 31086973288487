import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicResolver } from './modules/services/resolver/public.resolver';
import { CommunityResolver } from './modules/services/resolver/community.resolver';
import { CommunityUnregisteredResolver } from './modules/services/resolver/community-unregistered.resolver';
import { environment } from "../environments/environment";
import { AuthGuardCommunityGuard } from "./modules/services/auth-guards/auth-guard-community/auth-guard-community.guard";
import {
  AuthGuardUnregisteredGuard
} from "./modules/services/auth-guards/auth-guard-unregistered/auth-guard-unregistered.guard";

/**
 * all routing is done in app routing until now, we don't have real subrouting, the pages handle which components are to be displayed
 * all modules are lazy loaded
 */
const routes: Routes = [
  {
    path: 'rewards',
    loadChildren: () =>
      import('./modules/rewards/rewards.module').then((m) => m.RewardsModule),
    canActivate: [AuthGuardCommunityGuard],
    resolve: { data: CommunityResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'reward-point-history',
    loadChildren: () =>
      import('./modules/reward-point-history/reward-point-history.module').then(
        (m) => m.RewardPointHistoryModule
      ),
    canActivate: [AuthGuardCommunityGuard],
    resolve: { data: CommunityResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./modules/cart/cart.module').then((m) => m.CartModule),
    canActivate: [AuthGuardCommunityGuard],
    resolve: { data: CommunityResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/register/register.module').then(
        (m) => m.RegisterModule
      ),
    canActivate: [AuthGuardUnregisteredGuard],
    resolve: { data: CommunityUnregisteredResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./modules/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
    canActivate: [AuthGuardCommunityGuard],
    resolve: { data: CommunityResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'overview',
    loadChildren: () =>
      import('./modules/overview/overview.module').then(
        (m) => m.OverviewModule
      ),
    resolve: { data: PublicResolver },
    runGuardsAndResolvers: 'always'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview'
  },

];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(
    routes,
    {
      onSameUrlNavigation: 'reload',

      enableTracing: !environment.production
    }
  )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
