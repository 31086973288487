import { Component } from '@angular/core';
import {UserService} from "@neo-reward-engine-web/auth";
import {Router} from "@angular/router";
import {ToastService} from "../../services/toast/toast.service";
import {ProgramSettingsService} from "../../services/program-settings/program-settings.service";

@Component({
  selector: 'neo-reward-engine-web-landing-core',
  templateUrl: './landing-core.component.html',
  styleUrls: ['./landing-core.component.scss'],
})
export class LandingCoreComponent{

  /**
   *
   * @param userService injected to check the user permissions to register (maincontact)
   * @param router injected to route to registration page
   * @param toastService injected to inform non maincontact users about the registration permission error
   * @param programSettings injected to check if there is an active reward program
   */
  constructor(
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private programSettings: ProgramSettingsService
  ) {
  }

  get blobStorageToS(): string {
    return 'https://hdumediaassetmgr.blob.core.windows.net/service-footer/de_CH/HCH_2018_Saphira_Bonus_Programm_Gesch%C3%A4ftsbedingungen_Dienstleistungsprogramm.pdf';
  }

  /**
   * checks maincontact status and navigates to register or spawns toast message
   */
  navigateToRegister() {
    if (this.userService.userState$.value?.isMainContact) {
      this.router.navigate(['/register'], { queryParamsHandling: 'preserve' });
    } else if (this.userService.userState$.value?.isMainContact === false) {
      this.toastService.showInfoMessage('register.noMainContact');
    } else {
      window.location.href = window.location.href + 'register';
    }
  }
  /**
   *
   * @returns translation key for transloco with register button status (e.g. pending or unregistered)
   */
  getRegisterButtonStatusTKey(): string {
    return (
      'register.registerBtn.' +
      (this.userService.userState$.value?.status || 'Unregistered')
    );
  }

  get programIsActive(){
    return this.programSettings.programSettings$.value?.enabled;
  }

  get loggedIn(){
    return this.userService.userState$.value?.userType === 'Community' || this.userService.userState$.value?.userType === 'Admin';
  }

}
