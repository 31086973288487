import { RewardTransactionViewModel } from './reward-transaction-view.model';
import { DateTime } from 'luxon';
import { RewardTransactionDto } from '@neo-reward-engine-web/ecom-api';

/**
 * convertUtcIsoDateStringToAccountDateTime
 *
 * Helper function converting UtcIso DateTime representation
 * into luxon datetime.
 *
 * @param utcIsoDateString
 * @param zoneOffset
 */
export const convertUtcIsoDateStringToAccountDateTime = (
  utcIsoDateString: string,
  zoneOffset: string
): DateTime =>
  DateTime.fromISO(utcIsoDateString, { zone: 'UTC' }).setZone(zoneOffset);

/**
 * convertRewardTransactionToClientModel
 *
 * Helper function to convert Server RewardTransaction Model
 * to Client View Model.
 *
 * @param rewardTransaction
 * @param zoneOffsetOfAccount
 */
export const convertRewardTransactionToClientModel = (
  rewardTransaction: RewardTransactionDto,
  zoneOffsetOfAccount: string
): RewardTransactionViewModel => ({
  orderId:
    rewardTransaction.orderId === null || undefined
      ? undefined
      : rewardTransaction.orderId,
  points: rewardTransaction.points,
  type: rewardTransaction.type,
  usedPoints: rewardTransaction.usedPoints,
  expiringDate: convertUtcIsoDateStringToAccountDateTime(
    rewardTransaction.expiringDate,
    zoneOffsetOfAccount
  ),
  timeStamp: convertUtcIsoDateStringToAccountDateTime(
    rewardTransaction.timestamp,
    zoneOffsetOfAccount
  ),
  origin:
    rewardTransaction.ruleType != null ? rewardTransaction.ruleType : 'Unknown',
  rewardOrderId: rewardTransaction.rewardOrderId,
  adminUserId: rewardTransaction.adminUserId,
  comment: rewardTransaction.comment,

});
