/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NonCashBenefit } from './nonCashBenefit';
import { RewardProductType } from './rewardProductType';
import { LocalizedString } from './localizedString';


export interface RewardProduct {
    id?: string | null;
    name: Array<LocalizedString>;
    startDate: string;
    endDate: string;
    type?: RewardProductType;
    description?: Array<LocalizedString> | null;
    productImages?: Array<string> | null;
    mainImageIndex?: number;
    active?: boolean;
    advertised?: boolean;
    points: number;
    ssu: string;
    nonCashBenefit: Array<NonCashBenefit>;
    minimumTier?: string | null;
}

