import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "@neo-reward-engine-web/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardUnregisteredGuard implements CanActivate {

  /**
   *
   * @param userService injected to check usertype
   * @param router
   */
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    //
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      /** if user is logged in but not enabled resolve route */
      if (
        this.userService.userState$.value?.userType === 'Community' &&
        this.userService.userState$.value?.status !== 'Enabled'
      ) {
        resolve(true);
      } else if (
        /** if user is logged in but enabled don't resolve route -> dont change menu items but still navigate */
        this.userService.userState$.value?.userType === 'Community' &&
        this.userService.userState$.value?.status === 'Enabled'
      ) {
        this.router.navigate(['/overview'], {
          queryParamsHandling: 'preserve',
        });
        resolve(false);
      } else {
        /** if user is not logged in don't resolve route -> dont change menu items and navigate to landing page*/
        this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
        resolve(false);
      }
    });
  }

}
