/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RewardOrderDto } from './rewardOrderDto';
import { RewardAccountDto } from './rewardAccountDto';
import { RewardTransactionDto } from './rewardTransactionDto';


export interface RewardOrderViewDto { 
    order?: RewardOrderDto;
    transaction?: RewardTransactionDto;
    account?: RewardAccountDto;
}

