/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RewardAccountStatus } from './rewardAccountStatus';
import { SapAccountId } from './sapAccountId';
import { RelatedAccount } from './relatedAccount';
import { UserType } from './userType';


export interface UserInfo { 
    id?: string | null;
    logonId?: string | null;
    userType: UserType;
    contactName: string;
    language?: string | null;
    canRedeem?: boolean | null;
    timeZone?: string | null;
    sapAccountNumber?: SapAccountId;
    /**
     * Community user related accounts
     */
    relatedAccounts?: Array<RelatedAccount> | null;
    accountName?: string | null;
    /**
     * Community user related SSUs
     */
    ssu?: string | null;
    /**
     * Admin user related SSUs
     */
    relatedSsu?: Array<string> | null;
    contactId?: string | null;
    status?: RewardAccountStatus;
    isMainContact?: boolean | null;
    isSuperAdmin?: boolean | null;
    email?: string | null;
    /**
     * Returns true if the users program is enabled. Null if current user is of type Admin
     */
    programEnabled?: boolean | null;
}

